import { css, styled } from "styled-components";
interface FlexProps {
  alignitems?: "stretch" | "flex-start" | "flex-end" | "center" | "baseline";
  justifycontent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly";
  gap?: string;
  width?: string;
  height?: string;
  flexwrap?: string;
  padding?: string;
  margin?: string;
  fontfamily?: string;
}

export const FlexRow = styled.div<FlexProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifycontent }) =>
    justifycontent ? justifycontent : "center"};
  align-items: ${({ alignitems }) => (alignitems ? alignitems : "center")};
  gap: ${({ gap }) => (gap ? gap : "")};
  flex-wrap: ${({ flexwrap }) => (flexwrap ? flexwrap : "")};
  width: ${({ width }) => (width ? width : "")};
  height: ${({ height }) => (height ? height : "")};
  padding: ${({ padding }) => (padding ? padding : "")};
  margin: ${({ margin }) => (margin ? margin : "")};
`;
export const FlexColumn = styled.div<FlexProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifycontent }) =>
    justifycontent ? justifycontent : "center"};
  align-items: ${({ alignitems }) => (alignitems ? alignitems : "center")};
  gap: ${({ gap }) => (gap ? gap : "")};
  flex-wrap: ${({ flexwrap }) => (flexwrap ? flexwrap : "")};
  width: ${({ width }) => (width ? width : "")};
  height: ${({ height }) => (height ? height : "")};
  padding: ${({ padding }) => (padding ? padding : "")};
  margin: ${({ margin }) => (margin ? margin : "")};
`;

export const HorizontalLine = styled.hr`
  width: 100%;
  height: 1px;
  margin: 12px 0;
  border: ${(props) =>
    `1px solid ${props.theme.colors?.alpha?.black[10]}`}; // Wrap the "1px solid" part in backticks
`;
