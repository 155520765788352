import axios from "axios";
import { notify } from "../../shared/components/toaster/Toaster";
import { navigateTo } from "../../shared/utilities/navigate";

const api = axios.create({
  baseURL: process.env.API_BASE_URL + "/api/v1",
});

export const notificationApi = axios.create({
  baseURL: process.env.NOTIFICATION_API_URL + "/api/v1",
});

api.interceptors.request.use((config) => {
  const isRefreshTokenApi = config.url && config.url.includes("/user/generateAccessToken");
  const token = localStorage.getItem("token");
   if (isRefreshTokenApi) {
      const refreshToken = localStorage.getItem("refreshtoken");
      if (refreshToken) {
        config.headers.Authorization = `Bearer ${refreshToken}`;
      }
    } 
    if(!isRefreshTokenApi) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  return config;
});

notificationApi.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      console.log(error.response)
      const { status } = error.response;
      // notify.error(error.response.data?.message?.error);
      if (status >= 400 && status < 500) {
        if (error?.response?.data?.error == "Precondition Failed Exception") {
          console.log(error?.response?.data?.message?.message[0]);
          notify.error(error?.response?.data?.message?.message[0]);
        }
       else {
        notify.error(error.response.data?.message?.error);
        if (status === 401 || status === 403 )  {
          window.localStorage.clear();
          navigateTo("/");
        }
       }
      } else if (status >= 500) {
        notify.error("Internal Server Error");
      }
    
    } else {
      window.localStorage.clear();
      navigateTo("/");
      console.error("Network error:", error.message);
    }

    return Promise.reject(error);
  }
);

export default api;
