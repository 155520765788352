import { TextField, Typography, useTheme } from "@mui/material";

interface DynamicVariableFormProps {
  dynamicVariables: any[];
  register: any;
  formState: any;
}
const DynamicVariableForm = ({
  dynamicVariables,
  register,
  formState,
}: DynamicVariableFormProps) => {
  const theme = useTheme();
  return (
    <div>
      {dynamicVariables.map((variable) => (
        <div key={variable}>
          <TextField
            {...register(variable)}
            label={`Enter ${variable}`}
            margin="normal"
            fullWidth
          />
          {formState.errors[variable] && (
            <Typography sx={{ color: theme.colors.error.light }}>
              {formState.errors[variable]?.message}
            </Typography>
          )}
        </div>
      ))}
    </div>
  );
};

export default DynamicVariableForm;
