import { useNavigate, useRoutes } from "react-router-dom";
import router from "../../router";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { CssBaseline } from "@mui/material";
import ThemeProvider from "../../styles/theme/ThemeProvider";
import noInternetGif from "../../assets/images/nointernet.gif";
import ServerErrorGif from "../../assets/images/servererror.gif";
import Toaster from "../../shared/components/toaster/Toaster";
import { setNavigateCallback } from "../../shared/utilities/navigate";
import { UserProvider } from "../../shared/contexts/UserContext";

function App() {
  const content = useRoutes(router);
  const navigate = useNavigate();
  setNavigateCallback(navigate);
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Toaster />
        <UserProvider>
          <CssBaseline />
          <img src={ServerErrorGif} alt="No internet" style={{ display: "none" }} />
          <img src={noInternetGif} alt="No internet" style={{ display: "none" }} />
          {content}
        </UserProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
