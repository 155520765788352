import {
  AdminLoginType,
  ChangePasswordType,
  EnterpriseType,
  ModelType,
  PlanType,
  PreviewPromptType,
  ProfileType,
  PromptType,
  ReviewTemplateType,
  SettingType,
  presignedUrlType,
} from "../../shared/utilities/Types/types";
import api, { notificationApi } from "./interceptor";
import axios from "axios";

export const adminLogin = async (data: AdminLoginType) => {
  try {
    const response = await api.post("/user/adminLogin", data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getSubscriptionPlans = async (
  page: number,
  limit: number,
  search?: string
) => {
  try {
    let url = `/subscription/getPlans?page=${page}&limit=${limit}`;
    if (search) {
      url += `&search=${search}`;
    }
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const createPlan = async (data: PlanType) => {
  try {
    const response = await api.post("/subscription/createPlan", data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updatePlan = async (id: string, data: PlanType) => {
  try {
    const response = await api.put(`/subscription/updatePlan/${id}`, data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const deletePlan = async (id: string) => {
  try {
    const response = await api.delete(`/subscription/deletePlan/${id}`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getProfileDetails = async () => {
  try {
    const response = await api.get(`/user/userProfileDetails`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateProfileDetails = async (data: ProfileType) => {
  try {
    const response = await api.patch(`/user/updateUserProfile`, data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateSetting = async (data: SettingType) => {
  try {
    const response = await api.put(`/admin/updateSetting/`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

// const /api/v1/admin/updateSetting

export const getSubscriberList = async (
  page: number,
  limit: number,
  status: string,
  search: string
) => {
  try {
    let url = `/subscription/getUserSubscriberList?page=${page}&limit=${limit}`;
    if (status) {
      url += `&status=${status}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getSubscriberListById = async (id: string) => {
  try {
    let url = `/subscription/subscriptionDetails/${id}`;
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getUsersCount = async () => {
  try {
    const response = await api.get(`/subscription/getUsersCount`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getStripeTransactionList = async (
  page: number,
  limit: number,
  userId: string,
  status: string
) => {
  try {
    let url = `/payment/GetUserStripeTransactionList?page=${page}&limit=${limit}`;
    if (status) {
      url += `&paymentStatus=${status}`;
    }
    if (userId) {
      url += `&userId=${userId}`;
    }
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getGptPrompts = async (
  page: number,
  limit: number,
  search?: string,
  model?: string
) => {
  try {
    let url = `/admin/getGptPrompts?page=${page}&limit=${limit}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (model) {
      url += `&model_identity=${model}`;
    }
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getGptPromptsById = async (id: string) => {
  try {
    let url = `/admin/getGptPromptById?prompt_id=${id}`;
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updatePrompt = async (data: PromptType) => {
  try {
    const response = await api.put(`/admin/editGptPromptById`, data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const addPrompt = async (data: PromptType) => {
  try {
    const response = await api.post(`/admin/addNewPrompt`, data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getTemplateRequests = async (
  page: number,
  limit: number,
  status?: string
) => {
  try {
    let url = `/meeting/getTemplatesRequests?page=${page}&limit=${limit}`;
    if (status) {
      url += `&status=${status}`;
    }
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getTemplateRequestsById = async (templateId: string) => {
  try {
    let url = `/meeting/getTemplateRequest/${templateId}`;
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const reviewTemplate = async (data: ReviewTemplateType) => {
  try {
    const response = await api.post(`/meeting/reviewTemplate`, data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const changePassword = async (data: ChangePasswordType) => {
  try {
    const response = await api.patch(`/user/changePasswordUserSettings`, data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const refreshAccessToken = async () => {
  try {
    const response = await api.get(`/user/generateAccessToken`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getUserSubscriptionStats = async (params: any) => {
  try {
    let url = `/subscription/getUserSubscriptionStats${params}`;
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getTokenStats = async (params: any) => {
  try {
    let url = `/admin/getTokenStats${params}`;
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getTokenCounts = async (data: any) => {
  try {
    let url = `/admin/getTotalTokenCounts`;
    if (data) {
      url += `?userId=${data}`;
    }
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const previewPrompt = async (data: PreviewPromptType) => {
  try {
    const response = await api.post(`/admin/previewEditPrompt`, data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const generatePresignedUrl = async (data: presignedUrlType) => {
  try {
    const response = await api.get(`/presigned-url`, {
      params: data,
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getNotifications = async () => {
  try {
    const response = await notificationApi.get(
      `/notifications/push-notifications/get-unread-push-notifications`
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const markAsReadNotification = async (notificationId: string) => {
  try {
    const response = await notificationApi.patch(
      `/notifications/push-notifications/read-receipts/${notificationId}`
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const adminLogout = async () => {
  try {
    const response = await api.post(`user/logoutUser`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getWaitlist = async (
  page: number,
  limit: number,
  status: string,
  search: string
) => {
  try {
    let url = `user/waitlist?page=${page}&limit=${limit}`;
    if (status) {
      url += `&status=${status}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateWaitlistStatus = async (payload: any) => {
  try {
    const response = await api.patch(`user/waitlist`, payload);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getCalenderSyncedUsers = async (page: number, limit: number) => {
  try {
    const response = await api.get(
      `meeting/calendarsync?page=${page}&limit=${limit}`
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};
export const updateCalenderSync = async (payload) => {
  try {
    const response = await api.patch(`meeting/calendarsync`, payload);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getModelDetails = async (
  page: number,
  limit: number,
  search?: string
) => {
  try {
    let url = `/admin/model?page=${page}&limit=${limit}`;
    if (search) {
      url += `&search=${search}`;
    }
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const createModel = async (data: ModelType) => {
  try {
    const response = await api.post("/admin/model", data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateModel = async (id: string, data: ModelType) => {
  try {
    const response = await api.put(`/admin/model/${id}`, data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const deleteModel = async (id: string) => {
  try {
    const response = await api.delete(`/admin/model/${id}`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const addArcTokens = async (payload: any) => {
  try {
    const response = await api.put(`/admin/addUserArcTokens`, payload);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const allAffiliates = async (
  page: number,
  limit: number,
  status: string
) => {
  try {
    let url = `/user/allAffiliates?page=${page}&limit=${limit}&status=${status}`;
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getAffiliateUserDetails = async (email: string) => {
  try {
    let url = `/user/getUserDetailsByEmail/${email}`;
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const allTransactions = async (
  page: number,
  limit: number,
  status: string
) => {
  try {
    let url = `/user/allTransactions?page=${page}&limit=${limit}&status=${status}`;
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateAffiliate = async (payload: any) => {
  try {
    const response = await api.patch(`/user/updateAffiliate`, payload);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateTransaction = async (payload: any) => {
  try {
    const response = await api.patch(`/user/updateTransactionStatus`, payload);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const customRequests = async (
  page: number,
  limit: number,
  status: string
) => {
  try {
    let url = `/admin/CustomRequests?page=${page}&limit=${limit}&status=${status}`;
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateCustomRequests = async (payload: any) => {
  try {
    const response = await api.put(`/admin/updateCustomRequest`, payload);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getCustomRequestsById = async (id: string) => {
  try {
    let url = `/admin/CustomRequest/${id}`;
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const deleteEnterpriseRequest = async (id: string) => {
  try {
    const response = await api.delete(`/admin/removeCustomRequest/${id}`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

const enterpriseApi = axios.create({
  baseURL: process.env.API_BASE_URL + "/api/v1",
});

export const addEnterpriseRequest = async (data: EnterpriseType) => {
  try {
    const response = await enterpriseApi.post(
      `/user/createCustomRequest`,
      data
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getServiceList = async () => {
  try {
    const response = await api.get(`/statuspage/service`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const createService = async (data: any) => {
  try {
    const response = await api.post(`/statuspage/service`, data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateService = async (data: any) => {
  try {
    const response = await api.patch(`/statuspage/service`, data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getIncidentList = async () => {
  try {
    const response = await api.get(`/statuspage/incident`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const createIncident = async (data: any) => {
  try {
    const response = await api.post(`/statuspage/incident`, data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateIncident = async (data: any) => {
  try {
    const response = await api.patch(`/statuspage/incident`, data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getCouponDetails = async (
  page: number,
  limit: number,
  search?: string
) => {
  try {
    let url = `/subscription/getCoupons?page=${page}&limit=${limit}&search=${search}`;
    // if (search) {
    //   url += `&search=${search}`;
    // }
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const createCoupon = async (data: any) => {
  try {
    const response = await api.post("/subscription/createCoupon", data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateCoupon = async (id: string, data: any) => {
  try {
    const response = await api.patch(`/subscription/updateCoupon`, data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const deleteCoupon = async (id: string) => {
  try {
    const response = await api.delete(`/subscription/deleteCoupon/${id}`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getCouponById = async (id: string) => {
  try {
    let url = `/subscription/getCoupon/${id}`;
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getCouponStats = async () => {
  try {
    const response = await api.get(`/subscription/getCouponsData`);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getCouponUsers = async (
  page: number,
  limit: number,
  couponId?: string
) => {
  try {
    let url = `/subscription/getAllUsedCouponsData?page=${page}&limit=${limit}&couponId=${couponId}`;
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getUsersList = async (
  limit: number,
  offset: number,
  search?: string,
  fromDate?: string,
  toDate?: string,
  status?: string,
) => {
  try {
    let url = `/user?limit=${limit}&offset=${offset}`;
    const currentDate = new Date();

    if (status) {
      url += `&status=${status}`;
    }
    if (search) {
      url += `&search=${search}`;
    }

    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    } else {
      const sevenDaysBefore = currentDate;
      sevenDaysBefore.setDate(currentDate.getDate() - 7);
      const formattedDate = getFormattedDate(sevenDaysBefore);
      url += `&fromDate=${formattedDate}`;
    }

    if (toDate) {
      url += `&toDate=${toDate}`;
    } else {
      const formattedDate = getFormattedDate(currentDate);
      url += `&toDate=${formattedDate}`;
    }
    const response = await api.get(url);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

const getFormattedDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
