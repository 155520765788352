import { ModalContainer, ModalContent } from "./CustomModal.style";

interface ModalProps {
  children: React.ReactNode;
  onClose: Function;
  enableBackgroundClick?: boolean;
  nopadding?: boolean | undefined;
}
const CustomModal = (props: ModalProps) => {
  const { children, onClose, enableBackgroundClick, nopadding } = props;

  const handleBackgroundClick = (e: any) => {
    if (e.target === e.currentTarget && enableBackgroundClick) {
      onClose();
    }
  };

  return (
    <ModalContainer onClick={handleBackgroundClick}>
      <ModalContent nopadding={nopadding}>{children}</ModalContent>
    </ModalContainer>
  );
};

export default CustomModal;
