import { Box, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCouponById, getSubscriberListById } from "../../logic/apis/api";
import TokenUsageChart from "../../shared/components/TokenUsageChart/tokenUsageChart";
import TransactionHistory from "../sponsor/transactionhistory";
import CouponUserDetails from "./coupon-user";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CouponDetail = () => {
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const [userData, setUserData] = useState<any>();
  const [userDataLoader, setUserDataLoader] = useState(true);

  useEffect(() => {
    const fetchUserDataById = async () => {
      try {
        const response = await getCouponById(id);
        setUserDataLoader(false);
        setUserData(response);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    fetchUserDataById();
  }, []);

  return (
    <Box sx={{ m: 2 }}>
      <Box>
        <Typography variant="h6" style={{ fontSize: 30, fontWeight: 700 }}>
          Coupon Code Details
        </Typography>

        <Paper elevation={3} sx={{ padding: 1.5 }}>
          <Grid container spacing={2} gap={3}>
            <Grid item xs={12} sx={{ display: "flex", mt: 2 }}>
              <Grid container spacing={2} gap={5}>
                <Grid item xs={12} md={5} sx={{ display: "flex" }}>
                  <Box sx={{ display: "flex", my: 1, flexDirection: "column" }}>
                    <Typography variant="body1" sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}>
                      Coupon Code
                    </Typography>
                    <Typography variant="body1">{userData?.name || "N/A"}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", my: 1, flexDirection: "column" }}>
                    <Typography variant="body1" sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}>
                      Discount %
                    </Typography>
                    <Typography variant="body1">{userData?.percentOff}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", my: 1, flexDirection: "column" }}>
                    <Typography variant="body1" sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}>
                      Expiration
                    </Typography>
                    <Typography variant="body1">
                      {" "}
                      {new Date(userData?.redeemBy * 1000).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", my: 1, flexDirection: "column" }}>
                    <Typography variant="body1" sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}>
                      Created
                    </Typography>
                    <Typography variant="body1">
                      {" "}
                      {new Date(userData?.createdAt * 1000).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", my: 1, flexDirection: "column" }}>
                    <Typography variant="body1" sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}>
                      Users Redeemed
                    </Typography>
                    <Typography variant="body1">{userData?.timesRedeemed}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box sx={{ width: "100%", mt: 2 }}>
        <CouponUserDetails />
      </Box>
    </Box>
  );
};

export default CouponDetail;
