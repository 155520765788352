import { useEffect, useState } from "react";
import { styled } from "styled-components";
import TemplatePreviewIcon from "../../assets/images/templatePreview.png";
import ArcadiaLogo from "../../assets/images/arcadiaLogo.svg";
import { FlexColumn, FlexRow, HorizontalLine } from "../../styles/theme/sharedStyles";
import { ArcadiaPointsIcon, BackIcon } from "../../styles/theme/svgicon";
import { Typography, useTheme } from "@mui/material";

interface TemplatePreviewProps {
  onClose: () => void;
  data?: any;
}

const TemplatePreview = (props: TemplatePreviewProps) => {
  const theme = useTheme();
  const { onClose, data } = props;
  const stageNames = data?.stages.map((stage: any) => stage.name);
  const [activeStep, setActiveStep] = useState(stageNames[0]);
  const [suggestionList, setSuggestionList] = useState([]);

  useEffect(() => {
    const findSuggestionsByName = (data: any, nameToFind: string) => {
      const stage = data.find((item: any) => item.name === nameToFind);
      return stage ? stage.suggestions : [];
    };
    const suggestions = findSuggestionsByName(data?.stages, activeStep);
    setSuggestionList(suggestions);
  }, [data, activeStep]);

  return (
    <TemplatePreviewContainer>
      <FlexRow justifycontent="flex-start" width="100%">
        <BackButton onClick={onClose} theme={theme}>
          <BackIcon />
          <h3>Back to template</h3>
        </BackButton>
      </FlexRow>

      <TemplateInfoContainer>
        <TemplatePreviewImg src={data?.image || TemplatePreviewIcon} alt="preview" />
        <FlexColumn width="100%" alignitems="flex-start">
          <FlexRow>
            <CompanyLogoImg src={ArcadiaLogo} alt="companyLogo" />
          </FlexRow>
          <Typography variant="h3" fontWeight={600}>
            {data?.title}
          </Typography>
          <Typography variant="body1">{data?.description}</Typography>
        </FlexColumn>
      </TemplateInfoContainer>

      <HorizontalLine theme={theme} />

      <StepsContainer>
        {stageNames.map((step: any, index: number) => (
          <Steps key={index} activeTab={activeStep === step} onClick={() => setActiveStep(step)}>
            {step}
          </Steps>
        ))}
      </StepsContainer>

      <FlexColumn width="100%" alignitems="flex-start">
        <Typography variant="body1" fontWeight={700}>
          Arcadia ~ Suggestions and Recommendations
        </Typography>

        <SuggestionsListContainer>
          {!!suggestionList && suggestionList
            ? suggestionList?.map((suggestion) => (
                <SuggestionsQuestion theme={theme}>
                  <ArcadiaPointsIcon />
                  <p>{suggestion}</p>
                </SuggestionsQuestion>
              ))
            : null}
        </SuggestionsListContainer>
      </FlexColumn>
    </TemplatePreviewContainer>
  );
};

export default TemplatePreview;

const TemplatePreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    color: ${({ theme }) => theme.text};
    font-family: Outfit;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 12px 0;
  }
`;

const BackButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;

  h3 {
    font-size: 13px;
    color: ${(props) => props.theme.colors.alpha.black[70]}; // Use the theme's primary color: ;
  }
`;

const TemplateInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 785px;
  margin: 1rem 0;
  position: relative;

  button {
    position: absolute !important;
    right: 0;
    top: -7%;
  }
`;
const TemplatePreviewImg = styled.img`
  width: 200px;
  height: 200px;
  aspect-ratio: 1;
  border-radius: 10px;
  margin-right: 1.5rem;
`;

const CompanyLogoImg = styled.img`
  width: 20px;
  height: 20px;
  aspect-ratio: 1;
  margin-right: 0.5rem;
  border-radius: 50%;
  border: 1px solid "#E9EDF1";
`;

export const UseTemplateButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;

  svg {
    margin-right: 0.5rem;
  }

  h3 {
    color: ${({ theme }) => theme.white};
    font-family: Outfit;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-wrap: nowrap;
  }
`;

const SuggestionsQuestion = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  border-bottom: 1px solid #e9edf1;
  width: 100%;
  svg {
    margin-right: 0.5rem;
  }

  p {
    color: ${(props) => props.theme.colors.text};
  }
`;

const StepsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 785px;
  margin: 1rem 0;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer/Edge */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const Steps = styled.p<any>`
  border: ${(props) => (props.activeTab ? `1px solid blue` : ` 1px solid '#E9EDF1'`)};
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 4px 10px;
  border-radius: 10px;
  background: ${(props) => (props.activeTab ? `rgba(1, 111, 240, 0.08)` : "transparent")};
  cursor: pointer;
  margin: 0 0.5rem;
  text-wrap: nowrap;
`;

const SuggestionsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 30vh;
  overflow-y: auto;
  margin-top: 1rem;
  padding-top: 1rem;

  border-radius: 2px;
  background: var(--bg-linear, linear-gradient(44deg, #fff 0%, #f3f8ff 50.73%, #e8f0ff 100%));
`;
