import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getAffiliateUserDetails,
  getSubscriberListById,
  updateAffiliate,
} from "../../logic/apis/api";
import { Controller, useForm } from "react-hook-form";
interface RouteProps {
  id: string;
  email: string;
  status: string;
}
const AffiliateUserDetails = () => {
  const { state } = useLocation();
  const { id, status } = state;
  const { email } = useParams();
  const [userData, setUserData] = useState<any>();
  const [loader, setLoader] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amount: "",
      expirationTime: "",
      usersValid: "",
    },
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDataById = async () => {
      try {
        const response = await getAffiliateUserDetails(email);
        setUserData(response);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    fetchUserDataById();
  }, []);

  const handleApprove = async (data) => {
    if (status === "ACCEPTED") {
      if (data?.expirationTime || data?.usersValid) {
        const expirationTimeEpoch =
          Math.floor(new Date().getTime() / 1000) +
          parseInt(data?.expirationTime, 10) * 24 * 60 * 60;
        setLoader(true);
        try {
          const response = await updateAffiliate({
            affiliateId: id,
            status: status,
            expirationTime: expirationTimeEpoch || -1,
            usersValid: parseInt(data?.usersValid, 10) || -1,
            amount: parseInt(data?.amount, 10) || 0,
            remark: "",
          });
          if (response?.data) {
            setLoader(false);
            navigate("/earn-program");
          }
        } catch (error) {
          setLoader(false);
          console.error("An error occurred while updating affiliate:", error);
        }
      }
    }
  };

  // const validateUsersValid = (value) => {
  //   const usersValidValue = getValues("usersValid");
  //   if (!value || !usersValidValue) {
  //     setError("usersValid", {
  //       type: "required",
  //       message: "Code expiration per user is required",
  //     });
  //   }
  //   return true;
  // };

  // const validateExpirationTime = (value) => {
  //   const expirationTimeValue = getValues("expirationTime");
  //   console.log("value", value);
  //   console.log("expirationTimeValue", expirationTimeValue);

  //   if (!value || !expirationTimeValue) {
  //     console.log("hu");

  //     setError("expirationTime", {
  //       type: "required",
  //       message: "Code expiration time is required",
  //     });
  //   }
  //   return true;
  // };

  return (
    <Box sx={{ m: 2 }}>
      <Box>
        <Typography variant="h6" style={{ fontSize: 30, fontWeight: 700 }}>
          Details of {userData?.name || "N/A"}
        </Typography>

        <Paper elevation={3} sx={{ padding: 1.5 }}>
          <Grid container spacing={2} gap={3}>
            <Grid item xs={2} display="flex" flexDirection="column">
              <Box
                flexGrow={1}
                display="flex"
                flexDirection="column"
                sx={{ pe: 2 }}
              >
                <Paper
                  style={{ marginBottom: "16px", height: 164, width: 164 }}
                >
                  {userData?.profilePicture && (
                    <img
                      src={userData?.profilePicture}
                      alt="Sponsor"
                      style={{ width: "100%", height: "100%" }}
                    />
                  )}
                </Paper>
                <Typography
                  variant="h6"
                  style={{ fontSize: 16, fontWeight: 700, textAlign: "center" }}
                >
                  {userData?.name || "N/A"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={9} sx={{ display: "flex", mt: 2 }}>
              <Grid container spacing={2} gap={5}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: "flex", my: 1 }}>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}
                    >
                      Name:
                    </Typography>
                    <Typography variant="body1">
                      {userData?.name || "N/A"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", my: 1 }}>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}
                    >
                      Email Address:
                    </Typography>
                    <Typography variant="body1">
                      {userData?.email || "N/A"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Box sx={{ width: "100%", mt: 2 }}>
        <Paper elevation={3} sx={{ padding: 2 }}>
          <Typography variant="h6" style={{ fontSize: 26, fontWeight: 700 }}>
            Affiliate Code Setting
          </Typography>
          <form onSubmit={handleSubmit(handleApprove)}>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <Controller
                  name="amount"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Amount is required",
                    pattern: {
                      value: /^[1-9]\d*\.?\d*$/,
                      message: "Please enter a valid amount",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Amount"
                      margin="normal"
                      fullWidth
                      error={!!errors.amount}
                      helperText={errors?.amount?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="expirationTime"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Code expiration time is required",
                    pattern: {
                      value: /^[1-9]\d*\.?\d*$/,
                      message: "Please enter a valid expiration time",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Code expiration time ( days )"
                      margin="normal"
                      fullWidth
                      error={!!errors.expirationTime}
                      helperText={errors?.expirationTime?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={8}>
                <Controller
                  name="usersValid"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Code expiration per user is required",
                    pattern: {
                      value: /^[1-9]\d*\.?\d*$/,
                      message: "Please enter a valid code expiration per user",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Code expiration per user"
                      margin="normal"
                      fullWidth
                      error={!!errors.usersValid}
                      helperText={errors?.usersValid?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "end", m: 2 }}>
              <Button
                disabled={loader}
                type="submit"
                variant="contained"
                color="primary"
              >
                Send to User
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
    </Box>
  );
};

export default AffiliateUserDetails;
