export const FIREBASE_CONFIG = {
  // will remove commented code once it is tested.

  // apiKey: 'AIzaSyDS4GZzNqiyNuaKbx-exMDxJZV5TJKRsLE',
  // authDomain: 'arcadia-d168a.firebaseapp.com',
  // projectId: 'arcadia-d168a',
  // storageBucket: 'arcadia-d168a.appspot.com',
  // messagingSenderId: '617471501015',
  // appId: '1:617471501015:web:74abce43f7ae001af50692',
  // measurementId: 'G-VJQNR47PY7',
  apiKey: "AIzaSyBfnmraxt99PJU10lgYAO80mPtOoY-Fd_w",
  authDomain: "arcadia-b4de2.firebaseapp.com",
  projectId: "arcadia-b4de2",
  storageBucket: "arcadia-b4de2.appspot.com",
  messagingSenderId: "853135559337",
  appId: "1:853135559337:web:584c3f62ed0dccd00f37b8",
  measurementId: "G-T96X9WBNJ6",
};
export const VAPID_KEY =
  // 'BBy1XC234yW6YB1SaKkCKsdCBeNWmB-2pn-I0kzqKOMdIV7AG6S90s1HR4PJcDlwf7b9GoAJcpyQRomTLGBDZcw'
  "BGHm03-Nd1aRSx9b3nY5k5QNIPbyib1vLxIaVTITt-x0E-x-U4cNZYAb06sRTpYtq-NCqBlhARGHUsKHN634sxc";
