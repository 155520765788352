import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  createCoupon,
  createModel,
  deleteCoupon,
  deleteModel,
  getCouponDetails,
  getCouponStats,
  getModelDetails,
  updateCoupon,
  updateModel,
} from "../../logic/apis/api";
import SharedTable from "../../shared/components/Table";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import { Controller, useForm } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import SuspenseLoader from "../../shared/components/SuspenseLoader";
import { ModelType } from "../../shared/utilities/Types/types";
import { DatePicker } from "@mui/lab";
import Label from "../../shared/components/Label";
import styled from "styled-components";
import {
  CouponIcon,
  Redeemed,
  RedeemptionRate,
} from "../../styles/theme/svgicon";

const columns = [
  { id: "name", label: "Name", minWidth: "auto" },
  { id: "percentOff", label: "Discount %", minWidth: "auto" },
  { id: "expiration", label: "Expiration", minWidth: "auto" },
  { id: "createdAt", label: "Created At", minWidth: "auto" },
  { id: "usersRedeem", label: "Users Redeemed", minWidth: "auto" },
  { id: "status", label: "Status", minWidth: "auto" },
  { id: "action", label: "Action", minWidth: 170 },
];

const CouponCode = () => {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addModelLoader, setAddModelLoader] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [planLoader, setPlanLoader] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [updateModelLoader, setUpdateModelLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isDefault, setIsDefault] = useState(true);
  const [couponStats, setCouponStats] = useState<any>({});
  const [filters, setFilters] = useState({
    status: null,
  });
  const [userLoader, setUserLoader] = useState(true);
  const [models, setModels] = useState([]);
  const [checked, setChecked] = useState(true);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      amountOff: 0,
      currency: "",
      durationInMonths: 0,
      percentOff: null,
      redeemBy: null,
      maxRedemptions: false,
      productIds: [],
    },
  });

  const fetchCouponDetails = async () => {
    try {
      const limit = rowsPerPage;
      const search = searchValue;
      const response = await getCouponDetails(page + 1, limit, search);
      const couponStats = await getCouponStats();
      setCouponStats(couponStats);
      setUserLoader(false);
      setModels(response.coupons);
      setTotalCount(response.totalCount);
    } catch (error) {
      console.error("Error fetching subscribed users:", error);
    }
  };

  useEffect(() => {
    fetchCouponDetails();
  }, [rowsPerPage, page, filters, searchValue]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (rowperpage) => {
    const newRowsPerPage = parseInt(rowperpage, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset page when changing rows per page
  };

  const handleOpen = () => {
    setOpen(true);
    setIsEditing(false);
    reset({});
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const onSubmit = async (data) => {
    if (isEditing) {
      setUpdateModelLoader(true);
      let updatePayload: any = {
        couponId: selectedData.id,
        //         name: data.name,
        //         amountOff: Number(data.amountOff),
        currency: "usd",
        duration: "once",
        //         durationInMonths: Number(data.durationInMonths),
        percentOff: Number(data.percentOff),
        redeemBy: Number(Date.parse(data?.redeemBy) / 1000),
        //         maxRedemption: data.maxRedemption,
        productIds: [],
      };
      try {
        const result = await updateCoupon(selectedData.id, updatePayload);
        if (result) {
          setUpdateModelLoader(false);
          setOpen(false);
          setIsDeleting(false);
          fetchCouponDetails();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      setAddModelLoader(true);
      let payload: any = {
        //         amountOff: Number(data.amountOff),
        currency: "usd",
        duration: "once",
        //         durationInMonths: Number(data.durationInMonths),
        percentOff: Number(data.percentOff),
        redeemBy: Number(Date.parse(data?.redeemBy) / 1000),
        //         maxRedemption: data.maxRedemption,
        productIds: [],
      };
      try {
        const result = await createCoupon(payload);
        if (result) {
          setOpen(false);
          setAddModelLoader(false);
          setIsDeleting(false);
          fetchCouponDetails();
        }
      } catch (error) {
        setAddModelLoader(false);
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleEditClick = (row) => {
    let payload = {
      id: row._id,
    };

    let editData = models.filter((e) => e._id === row._id)[0];

    const date = new Date(editData.redeemBy * 1000);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    console.log(formattedDate);
    let initial_values = {
      //       amountOff: editData.description,
      //       currency: editData.modelIdentity,
      //       durationInMonths: editData.inputTokenPrice,
      percentOff: editData.percentOff,
      redeemBy: formattedDate,
      //       maxRedemptions: editData.isActive,
      productIds: [],
    };
    setIsActive(editData.isActive);
    setIsDefault(editData.isDefault);
    reset(initial_values);
    setIsEditing(true);
    setSelectedData(payload);
    setOpen(true);
  };

  const handleDeleteClick = (row) => {
    setDeleteId(row._id);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    try {
      setLoader(true);
      const result = await deleteCoupon(deleteId);
      if (result) {
        setIsDeleting(true);
        setOpenDelete(false);
        fetchCouponDetails();
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
    setOpenDelete(false);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedData(null);
    setOpenDelete(false);
    reset({});
  };

  const formatDate = (dateToFormat) => {
    if (dateToFormat) {
      const date = new Date(dateToFormat);
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    } else {
      return "";
    }
  };

  type Color = "error" | "info" | "secondary" | "warning" | "success";

  const getStatusLabel = (
    status: string | "pending" | "rejected" | "approved" | "all"
  ): JSX.Element => {
    let color = "";
    let text = "";
    switch (status) {
      case "pending":
        text = "Pending";
        color = "secondary";
        break;
      case "expired":
        text = "Expired";
        color = "error";
        break;
      case "active":
        text = "Active";
        color = "success";
        break;
      default:
        text = "All";
        color = "warning";
        break;
    }
    return <Label color={color as Color}>{text}</Label>;
  };

  const handleToggleActive = async (data) => {
    console.log(data);
    try {
      const updatedData = {
        isActive: !data.isActive,
        couponId: data?._id,
        productIds: [],
      };

      const resp = await updateCoupon(data?._id, updatedData);
      if (resp) {
        fetchCouponDetails();
      }
    } catch (error) {
      console.error("Error updating isActive status:", error);
      // Handle error
    }
  };

  const formattedData = models.map((data) => {
    const date = new Date(data.redeemBy * 1000);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;
    const currentTimestamp = Math.floor(Date.now() / 1000);
    let status;

    if (currentTimestamp > data.redeemBy) {
      status = "expired";
    } else {
      status = "active";
    }
    return {
      id: data?._id,
      name: (
        <Box
          onClick={() => handleRowClick(data)}
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          {data.name ? data.name : "N/A"}
        </Box>
      ),
      percentOff: data.percentOff ? data.percentOff : 0,
      expiration: formattedDate ? formattedDate : "-",
      usersRedeem: data.timesRedeemed ? data.timesRedeemed : 0,
      createdAt: data.createdAt ? formatDate(data.createdAt) : 0,
      status: getStatusLabel(status),
      action: (
        <>
          <Switch
            color="primary"
            checked={data?.isActive}
            onChange={() => handleToggleActive(data)}
            inputProps={{ "aria-label": "controlled" }}
          />
          <IconButton
            sx={{
              "&:hover": {
                background: theme.colors.primary.lighter,
              },
              color: theme.palette.primary.main,
            }}
            color="inherit"
            size="small"
            onClick={() => handleEditClick(data)}
          >
            <EditTwoToneIcon fontSize="small" sx={{ color: "#0481D9" }} />
          </IconButton>
          <IconButton
            sx={{
              "&:hover": { background: theme.colors.error.lighter },
              color: theme.palette.error.main,
            }}
            color="inherit"
            size="small"
            onClick={() => handleDeleteClick(data)}
          >
            <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </>
      ),
    };
  });

  const handleRowClick = (data) => {
    if (data) {
      navigate(`/coupon-details/${data?._id}`);
    } else return;
  };

  return (
    <Container component="main">
      <Grid item xs={12} sm={12} mt={3}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={3}>
            <CardWrapper
              border="1px solid var(--Storke, #e9edf1)"
              background="#F6FEFE"
              boxShadow="0px 30px 54px 0px rgba(227, 253, 234, 0.60)"
            >
              <CouponWrapper>
                <GreenCircle color="#3be6c5" />
                <CouponIcon />
              </CouponWrapper>
              <CouponInfo>
                <CouponNumber>{couponStats?.totalCoupons}</CouponNumber>
                <p>Coupon Generated</p>
              </CouponInfo>
            </CardWrapper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <CardWrapper
              border="1px solid var(--Storke, #e9edf1)"
              background="#FFF5F5"
              boxShadow="0px 30px 54px 0px rgba(255, 233, 232, 0.60)"
            >
              <CouponWrapper>
                <GreenCircle color="#FF7E5D" />
                <RedeemptionRate />
              </CouponWrapper>
              <CouponInfo>
                <CouponNumber>{couponStats?.redeemRate}</CouponNumber>
                <p>Redeemption Rate</p>
              </CouponInfo>
            </CardWrapper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <CardWrapper
              border="1px solid var(--Storke, #e9edf1)"
              background="#F3F0FF"
              boxShadow="0px 30px 54px 0px rgba(230, 223, 255, 0.60)"
            >
              <CouponWrapper>
                <GreenCircle color="#9980FF" />
                <Redeemed />
              </CouponWrapper>
              <CouponInfo>
                <CouponNumber>{couponStats?.totalRedeemed}</CouponNumber>
                <p>Users Redeemed</p>
              </CouponInfo>
            </CardWrapper>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ ms: 2, mt: 2 }}
      >
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            List of Coupon Codes
          </Typography>
        </Grid>
        <Grid item>
          <Button
            sx={{ my: 2 }}
            variant="contained"
            onClick={handleOpen}
            startIcon={<AddTwoToneIcon fontSize="small" />}
          >
            Add Coupon
          </Button>
        </Grid>
      </Grid>

      <SharedTable
        columns={columns}
        data={formattedData}
        page={page}
        tableBodyLoader={userLoader}
        rowsPerPage={rowsPerPage}
        totalRows={totalCount}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        searchFilter={undefined}
        onSearch={handleSearch}
        searchFilterVisible={true}
        selectableRows={false}
        onRowClick={undefined}
      ></SharedTable>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ overflowX: "hidden" }}
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={{ padding: "16px 24px 0px 24px" }}
        >
          {isEditing ? "Update Coupon" : "Add New Coupon"}
        </DialogTitle>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ width: 550 }}
        >
          <DialogContent>
            <Box
              sx={{
                maxHeight: "calc(100vh - 250px)",
                overflowY: "auto",
                maxWidth: "100%",
              }}
            >
              <div>
                <Controller
                  name="percentOff"
                  control={control}
                  rules={{
                    required: "Discount % is required",
                    pattern: {
                      value: /^(\d+(\.\d*)?|\.\d+)$/,
                      message: "Please enter a valid number",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      label="Discount"
                      margin="normal"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      error={!!errors.percentOff}
                      helperText={errors?.percentOff?.message}
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  name="redeemBy"
                  control={control}
                  rules={{
                    required: "Redeem by is required",
                  }}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      label="Redeem By"
                      inputFormat="dd/MM/yyyy"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          fullWidth
                          error={!!errors.redeemBy}
                          helperText={errors?.redeemBy?.message}
                        />
                      )}
                    />
                  )}
                />
              </div>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              type="submit"
              variant="contained"
              disabled={addModelLoader || updateModelLoader}
            >
              Submit
            </Button>
          </DialogActions>
        </Box>
        {(updateModelLoader || addModelLoader) && <SuspenseLoader left="0%" />}
      </Dialog>
      <Dialog
        open={openDelete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: 400, height: 300 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <DeleteIcon
              sx={{ fontSize: 70, m: 2, color: "rgba(204, 43, 53, 1)" }}
            />
            <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>
              Delete
            </Typography>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ m: 2, fontWeight: 600 }}
            >
              Are you sure you want to delete this Coupon?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ background: "rgba(204, 43, 53, 1)" }}
            onClick={handleDelete}
            autoFocus
            disabled={loader}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
export default CouponCode;

const CardWrapper = styled.div<any>`
  border-radius: 22px;
  border: ${({ border }) => border || "1px solid var(--Storke, #e9edf1)"};
  background: ${({ background }) => background || "#f6fefe"};
  box-shadow: ${({ boxShadow }) =>
    boxShadow || "0px 30px 54px 0px rgba(227, 253, 234, 0.6)"};
  width: 201px;
  height: 142px;
  flex-shrink: 0;
  padding: 20px;
`;

const CouponWrapper = styled.div`
  position: relative;
  display: inline-block;
  svg {
    z-index: 1;
    position: relative;
  }
`;

const GreenCircle = styled.div<any>`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: ${({ color }) => color || "#3be6c5"};
`;

const CouponInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0px;
  gap: 40px;
  justify-content: space-between;
`;

const CouponNumber = styled.div`
  color: #2a2a30;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
