import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { RouteObject } from "react-router";

import SidebarLayout from "./layouts/SidebarLayout";
import BaseLayout from "./layouts/BaseLayout";

import SuspenseLoader from "./shared/components/SuspenseLoader";
import SponsorDetailComponent from "./modules/sponsor/sponsorDetail";
import AuthRedirect, {
  AuthRedirect1,
} from "./shared/utilities/Auth/AuthRedirect";
import TemplateDetails from "./modules/templates/TemplateDetails";
import EarnProgram from "./modules/earnProgram";
import AffiliateUserDetails from "./modules/earnProgram/affiliateUserDetails";
import Enterprise from "./modules/enterprise";
import EnterpriseDetail from "./modules/enterprise/enterpriseDetail";
import Services from "./modules/service";
import Incidents from "./modules/incidents";
import CouponCode from "./modules/coupon-code";
import CouponDetail from "./modules/coupon-code/details";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader left="10%" />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Login = Loader(lazy(() => import("./modules/Login")));
const Overview = Loader(lazy(() => import("./modules/overview")));
const Sponsors = Loader(lazy(() => import("./modules/sponsor")));
const Model = Loader(lazy(() => import("./modules/ai_model")));
const Subscription = Loader(lazy(() => import("./modules/subscription")));
const Settings = Loader(lazy(() => import("./modules/settings")));
const Prompts = Loader(lazy(() => import("./modules/prompts")));
const Templates = Loader(lazy(() => import("./modules/templates")));
const Waitlist = Loader(lazy(() => import("./modules/waitlist")));
const UserCalenderSync = Loader(
  lazy(() => import("./modules/user-calender-sync"))
);
const Users = Loader(lazy(() => import("./modules/users")));

// Status

const Status404 = Loader(
  lazy(() => import("./modules/pages/Status/Status404"))
);

const routes: RouteObject[] = [
  {
    path: "",
    element: (
      <AuthRedirect>
        <BaseLayout />
      </AuthRedirect>
    ),
    children: [
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "status",
        children: [
          {
            path: "",
            element: <Navigate to="404" replace />,
          },
          {
            path: "404",
            element: <Status404 />,
          },
        ],
      },
      {
        path: "*",
        element: <Status404 />,
      },
    ],
  },
  {
    path: "",
    element: (
      <AuthRedirect1>
        <SidebarLayout />
      </AuthRedirect1>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="overview" replace />,
      },
      {
        path: "overview",
        element: <Overview />,
      },
      {
        path: "subscribers",
        element: <Sponsors />,
      },
      {
        path: "/subscriberdetails/:id",
        element: <SponsorDetailComponent />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "subscription",
        element: <Subscription />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "prompts",
        element: <Prompts />,
      },
      {
        path: "templates",
        element: <Templates />,
      },
      {
        path: "templates/:id",
        element: <TemplateDetails />,
      },
      {
        path: "waitlist",
        element: <Waitlist />,
      },
      {
        path: "model",
        element: <Model />,
      },
      { path: "users-calender-sync", element: <UserCalenderSync /> },
      {
        path: "earn-program",
        element: <EarnProgram />,
      },
      {
        path: "services",
        element: <Services />,
      },
      {
        path: "incidents",
        element: <Incidents />,
      },
      {
        path: "earn-program/:email",
        element: <AffiliateUserDetails />,
      },
      {
        path: "enterprise",
        element: <Enterprise />,
      },
      {
        path: "enterprise/:id",
        element: <EnterpriseDetail />,
      },
      {
        path: "coupon-code",
        element: <CouponCode />,
      },
      {
        path: "coupon-details/:id",
        element: <CouponDetail />,
      },
    ],
  },
  {
    path: "management",
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="transactions" replace />,
      },
    ],
  },
];

export default routes;
