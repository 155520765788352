import { Container, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { createCoupon, createModel, deleteCoupon, deleteModel, getCouponDetails, getCouponStats, getCouponUsers, getModelDetails, updateCoupon, updateModel } from "../../logic/apis/api";
import SharedTable from "../../shared/components/Table";
import { Controller, useForm } from "react-hook-form";
import Label from "../../shared/components/Label";
import styled from "styled-components";
import { CouponIcon, Redeemed, RedeemptionRate } from "../../styles/theme/svgicon";

const columns = [
  { id: "user", label: "User", minWidth: "auto" },
  { id: "expiration", label: "Redemmed time", minWidth: "auto" },
  { id: "discountAmount", label: "Discount Amount", minWidth: "auto" },
];

const CouponUserDetails = () => {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [updateModelLoader, setUpdateModelLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isDefault, setIsDefault] = useState(true);
  const [couponStats, setCouponStats] = useState<any>({});
  const [filters, setFilters] = useState({
    status: null,
  });
  const [userLoader, setUserLoader] = useState(true);
  const [models, setModels] = useState([]);
  const [checked, setChecked] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      amountOff: 0,
      currency: "",
      durationInMonths: 0,
      percentOff: null,
      redeemBy: null,
      maxRedemptions: false,
      productIds: [],
    },
  });

  const fetchCouponDetails = async () => {
    try {
      const limit = rowsPerPage;
      const search = searchValue;
      const response = await getCouponUsers(page + 1, limit, id);
      setUserLoader(false);
      setModels(response.usedCouponData);
      setTotalCount(response.totalCount);
    } catch (error) {
      console.error("Error fetching subscribed users:", error);
    }
  };

  useEffect(() => {
    fetchCouponDetails();
  }, [rowsPerPage, page, filters, searchValue]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (rowperpage) => {
    const newRowsPerPage = parseInt(rowperpage, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleOpen = () => {
    setOpen(true);
    setIsEditing(false);
    reset({});
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const formatDate = (dateToFormat) => {
    if (dateToFormat) {
      const date = new Date(dateToFormat);
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    } else {
      return "";
    }
  };

  type Color = "error" | "info" | "secondary" | "warning" | "success";

  const getStatusLabel = (status: string | "pending" | "rejected" | "approved" | "all"): JSX.Element => {
    let color = "";
    let text = "";
    switch (status) {
      case "pending":
        text = "Pending";
        color = "secondary";
        break;
      case "expired":
        text = "Expired";
        color = "error";
        break;
      case "active":
        text = "Active";
        color = "success";
        break;
      default:
        text = "All";
        color = "warning";
        break;
    }
    return <Label color={color as Color}>{text}</Label>;
  };

  const formattedData = models.map((data) => {
    const date = new Date(data.redeemBy * 1000);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;
    const currentTimestamp = Math.floor(Date.now() / 1000);
    let status;

    if (currentTimestamp > data.redeemBy) {
      status = "expired";
    } else {
      status = "active";
    }
    return {
      id: data?._id,
      user: data?.name ? data?.name : "N/A",
      expiration: data.createdAt ? formatDate(data.createdAt) : 0,
      discountAmount: data?.discountAmount ? data?.discountAmount : 0,
    };
  });

  return (
    <Container component="main">
      <SharedTable
        columns={columns}
        data={formattedData}
        page={page}
        tableBodyLoader={userLoader}
        rowsPerPage={rowsPerPage}
        totalRows={totalCount}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        searchFilter={undefined}
        onSearch={handleSearch}
        searchFilterVisible={true}
        selectableRows={false}
        onRowClick={undefined}
      ></SharedTable>
    </Container>
  );
};
export default CouponUserDetails;
