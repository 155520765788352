import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, useNavigate } from "react-router-dom";

import "nprogress/nprogress.css";
import App from "./modules/app/App";
import { SidebarProvider } from "./shared/contexts/SidebarContext";
import * as serviceWorker from "./serviceWorker";
import "react-toastify/dist/ReactToastify.css";
import  { Toaster } from "react-hot-toast";
import { GlobalStyle } from "./styles/theme/globalFont";
import NoInternet from "./modules/pages/Status/NoInternet";
import NetworkErrorDialog from "./modules/pages/Status/ServerError";
import { useEffect, useState } from "react";
import { refreshAccessToken } from "./logic/apis/api";
import { UserProvider } from "./shared/contexts/UserContext";

const ErrorProvider = ({ children }) => {
  const navigate = useNavigate();
  const [networkError, setNetworkError] = useState(false);
  var intervalId: any;

  const refreshToken = async () => {
    try {
      const resp = await refreshAccessToken();
      window.localStorage.setItem("token", resp);
    } catch (error) {
      console.error("Error refreshing token:", error);
      window.localStorage.clear();
      navigate("/");
    }
  };

  useEffect(() => {
    const refreshTok = localStorage.getItem("refreshtoken");
    if (!refreshTok) {
      navigate("/");
    }
  
    const refreshTokenIfNeeded = async () => {
      const expiresIn = localStorage.getItem("tokenExpireAt");
      const expirationTimestamp = parseInt(expiresIn, 10) * 1000;
      const currentTimestamp = Date.now();
      const timeDifference = expirationTimestamp - currentTimestamp;
  
      if (timeDifference < 180000) { 
        try {
          const resp = await refreshAccessToken();
          window.localStorage.setItem("token", resp?.token);
          window.localStorage.setItem("tokenExpireAt", resp?.tokenExpireAt);
        } catch (error) {
          console.error("Error refreshing token:", error);
          window.localStorage.clear();
          navigate("/");
        }
      }
    };
  
    // Initial check
    refreshTokenIfNeeded();
  
    // Set up interval to periodically check
    const intervalId = setInterval(refreshTokenIfNeeded, 300000); // Check every minute
  
    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  
  }, []);
  

  const handleCloseDialog = () => {
    setNetworkError(false);
  };

  return (
    <>
      <NoInternet />
      {networkError && <NetworkErrorDialog handleCloseDialog={handleCloseDialog} networkError={networkError} />}
        {children}
      <Toaster />
    </>
  );
};

ReactDOM.render(
  <HelmetProvider>
    <GlobalStyle />
    <SidebarProvider>
      <UserProvider>
      <BrowserRouter>
        <ErrorProvider>
          <App />
        </ErrorProvider>
      </BrowserRouter>
      </UserProvider>
    </SidebarProvider>
  </HelmetProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
