import { Box, FormControl, Grid, MenuItem, Select, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { TokenModel, TokenType } from "../../utilities/Types/types";
import { Line } from "react-chartjs-2";
import { getTokenCounts, getTokenStats } from "../../../logic/apis/api";
import { useParams } from "react-router-dom";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
interface TokenProps {
 id?: string;
}
const TokenUsageChart = (TokenProps) => {
  const [tokenUsageData, setTokenUsageData] = useState(null);
  const [totalInputTokens, setTotalInputTokens] = useState(null);
  const [totalOutputTokens, setTotalOutputTokens] = useState(null);
  const [tokenStartYear, setTokenStartYear] = useState<string | number>(2024);
  const [tokenMonth, setTokenMonth] = useState<number | string>("");
  const [tokenType, setTokenType] = useState<number | string>("");
  const [tokenModel, setTokenModel] = useState<number | string>("");
  const yearOptions = [
    { label: 2023, value: 2023 },
    { label: 2024, value: 2024 },
    { label: 2025, value: 2025 },
  ]; 
  const monthOptions = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];
  const userId = TokenProps.id;
  useEffect(()=>{
    const fetchTokenCount = async () => {
      try {
        const response = await getTokenCounts(userId ? userId:"");
        setTotalInputTokens(response?.totalInputTokens);
        setTotalOutputTokens(response?.totalOutputTokens);
      } catch (error) {
        console.error("Error fetching token usage:", error);
      }
    };

    fetchTokenCount();
  },[userId])

  const fetchTokenStats = async (data) => {
    try {
      const response = await getTokenStats(data);
      if (response?.tokenStats) {
        const data = {
          labels: [],
          datasets: [
            {
              label: "Input Token Count",
              data: [],
              fill: false,
              borderColor: "rgb(75, 192, 192)",
            },
            {
              label: "Output Token Count",
              data: [],
              fill: false,
              borderColor: "rgb(255, 204, 51)",
            },
          ],
        };

        if (tokenMonth == "") {
          const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
          response?.tokenStats.forEach((stat) => {
            const monthIndex = stat._id.month - 1;
            data.labels.push(monthNames[monthIndex]);
            data.datasets[0].data.push(stat.inputTokenSum);
            data.datasets[1].data.push(stat.outputTokenSum);
          });
        } else {
          response?.tokenStats.forEach((stat) => {
            const { timestamp, inputTokenSum,outputTokenSum } = stat;
            const date = new Date(timestamp);
            const monthValue = date.getMonth() + 1;

            if (monthValue === tokenMonth) {
              const day = date.getDate();
              data.labels.push(day);
              data.datasets[0].data.push(inputTokenSum);
              data.datasets[1].data.push(outputTokenSum);
            }
          });
        }

        setTokenUsageData(data);
      }
    } catch (error) {
      console.error("Error fetching subscribed users:", error);
    }
  };

  let queryParams: any;

  useEffect(() => {
    if (tokenStartYear) {
      queryParams = `?startYear=${tokenStartYear}`;
    }
    if (tokenMonth) {
      queryParams = queryParams + `&month=${tokenMonth}`;
    }
    if (tokenType) {
      queryParams = queryParams + `&type=${tokenType}`;
    }
    if (tokenModel) {
      queryParams = queryParams + `&model=${tokenModel}`;
    }
    if (userId) {
      queryParams = queryParams + `&userId=${userId}`;
    }
    fetchTokenStats(queryParams)
  }, [tokenStartYear,tokenMonth,tokenType,userId,tokenModel]);

  const formatNumber = (value)=> {
    const abbreviations = ["", "k", "M", "B", "T"];
  const order = Math.floor(Math.log10(value) / 3);
  const suffix = abbreviations[order];
  
  let formattedValue = (value / 10 ** (order * 3)).toFixed(1);

  // Remove trailing zeros after the decimal point
  formattedValue = formattedValue.replace(/\.0+$/, '');

  return suffix ? `${formattedValue}${suffix}` : value;
  }

  const tokenOptions = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: tokenMonth ? "Days in a Month" : "Month",
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of Tokens",
        },
        ticks: {
          callback: function (value) {
            if (Number.isInteger(value) && value >= 0) {
              return formatNumber(value);
            }
            return null;
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
        display: true,
      },
      title: {
        display: false,
      },
    },
  };

  return (
    <Box
    style={{
      padding: "1rem",
      border: "1px solid #E6F4FF",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: 12,
      background: "#FFFFFF",
    }}
  >
    <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between"}}>
      {tokenUsageData ? <h2>Token Usage - {tokenType? tokenType:'All'}</h2> : <Skeleton variant="text" width={100} />}
      <Box>
      { <h3>Total Input Tokens - {totalInputTokens}</h3> }
      { <h3>Total Output Tokens - {totalOutputTokens}</h3> }
      </Box>
     
    </Grid>
    {tokenUsageData ? (
      <Grid item xs={12} container justifyContent="flex-end">
      

        <FormControl sx={{ m: 1, width: 100, mt: 3 }} size="small">
          <Select value={tokenStartYear} onChange={(event) => setTokenStartYear(event.target.value)}>
            <MenuItem disabled value="">
              <span>Select</span>
            </MenuItem>
            {yearOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, width: 130, mt: 3 }} size="small">
          <Select value={tokenMonth} onChange={(event) => setTokenMonth(event.target.value)} displayEmpty>
            <MenuItem value="">
              <span>Select</span>
            </MenuItem>
            {monthOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 130, mt: 3 }} size="small">
          <Select value={tokenType} onChange={(event) => setTokenType(event.target.value)} displayEmpty>
            <MenuItem value="">
              <span>Select</span>
            </MenuItem>
            {Object.values(TokenType).map((tokenType) => (
             <MenuItem key={tokenType} value={tokenType}>
             {tokenType}
           </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 130, mt: 3 }} size="small">
          <Select value={tokenModel} onChange={(event) => setTokenModel(event.target.value)} displayEmpty>
            <MenuItem value="">
              <span>Select</span>
            </MenuItem>
            {Object.values(TokenModel).map((tokenModel) => (
             <MenuItem key={tokenModel} value={tokenModel}>
             {tokenModel}
           </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    ) : (
      <Grid item xs={12} container justifyContent="flex-end">
        <Skeleton variant="text" width={300} height={50} />
      </Grid>
    )}
    <Grid item xs={12}>
      {tokenUsageData ? <Line data={tokenUsageData} options={tokenOptions} height={70}/> : <Skeleton variant="rectangular" height={200} />}
    </Grid>
  </Box>
  );
};

export default TokenUsageChart;
