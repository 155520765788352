import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Radio, RadioGroup, FormControlLabel, TextField, Box, Typography } from "@mui/material";
import { updateAffiliate, updateCustomRequests, updateTransaction } from "../../logic/apis/api";
import { useNavigate } from "react-router";

const DenyReason = ({ onClose, userId, status, onDenySuccessChange }) => {
  const [selectedReason, setSelectedReason] = useState("");
  const [customReason, setCustomReason] = useState("");
  const [isReasonSelected, setIsReasonSelected] = useState(true);
  const [denySuccess, setDenySuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    onDenySuccessChange(denySuccess);
  }, [denySuccess, onDenySuccessChange]);

  const handleReject = async () => {
    if (selectedReason == "") {
      setIsReasonSelected(false);
      return;
    }

    if (status == "affiliate") {
      const payload = {
        affiliateId: userId,
        status: "REJECTED",
        expirationTime: 0,
        usersValid: 0,
        amount: 0,
        remark: selectedReason !== "other" ? selectedReason : customReason,
      };
      const resp = await updateAffiliate(payload);
      if (resp?.data) {
        setDenySuccess(true);
      }
    } else if (status == "transaction") {
      const payload = {
        transactionId: userId,
        status: "REJECTED",
        remark: selectedReason !== "other" ? selectedReason : customReason,
      };
      const resp = await updateTransaction(payload);
      if (resp?.data) {
        setDenySuccess(true);
      }
    } else if (status == "enterprise") {
      const payload = {
        requestId: userId,
        status: "rejected",
        rejectReason: selectedReason !== "other" ? selectedReason : customReason,
      };
      const resp = await updateCustomRequests(payload);
      if (resp?.success) {
        setDenySuccess(true);
      }
    }

    onClose();
  };

  const handleGoBack = () => {
    onClose();
  };

  const handleRadioChange = (e) => {
    setSelectedReason(e.target.value);
    setIsReasonSelected(true); // Reset the error message when a reason is selected
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Reason for Denial</DialogTitle>
      <DialogContent>
        <RadioGroup value={selectedReason} onChange={handleRadioChange}>
          <FormControlLabel value="Lack of Originality" control={<Radio />} label="Lack of Originality" />
          <FormControlLabel value="Lack of Security" control={<Radio />} label="Lack of Security" />
          <FormControlLabel value="Legal Concerns" control={<Radio />} label="Legal Concerns" />
          <FormControlLabel value="Limited Market Potential" control={<Radio />} label="Limited Market Potential" />
          <FormControlLabel value="Technical Issues" control={<Radio />} label="Technical Issues" />
          <FormControlLabel value="other" control={<Radio />} label="Other Reason" />
        </RadioGroup>
        {selectedReason === "other" && (
          <Box mt={2}>
            <TextField label="Custom Reason" variant="outlined" fullWidth value={customReason} onChange={(e) => setCustomReason(e.target.value)} />
          </Box>
        )}
        {!isReasonSelected && (
          <Typography variant="caption" color="error" mt={2}>
            Please select a denial reason.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReject} variant="contained" color="primary">
          Reject
        </Button>
        <Button onClick={handleGoBack} variant="outlined" color="primary">
          Go Back
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DenyReason;
