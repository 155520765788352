import { Button, Card } from "@mui/material";
import { useEffect, useState } from "react";
import { allAffiliates, customRequests } from "../../logic/apis/api";
import SharedTable from "../../shared/components/Table";
import { Box } from "@mui/system";
import DenyReason from "../earnProgram/denyReason";
import { useNavigate } from "react-router";

const columns = [
  { id: "name", label: "Name", width: "300px" },
  { id: "email", label: "Email", width: "250px" },
  { id: "joinedOn", label: "Requested On", width: "250px" },
  { id: "action", label: "Action", width: "250px" },
];

const AccepetedRequests = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [acceptedRequests, setAcceptedRequests] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [affiliateLoader, setAffiliateLoader] = useState(true);
  const [denydialog, setDenyDialog] = useState(false);
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();

  const fetchAffiliates = async () => {
    try {
      const limit = rowsPerPage;
      const response = await customRequests(page + 1, limit, "approved");
      setAffiliateLoader(false);
      setAcceptedRequests(response?.requests);
      setTotalCount(response?.totalCount);
    } catch (error) {
      console.error("Error fetching subscribed users:", error);
    }
  };

  useEffect(() => {
    fetchAffiliates();
  }, [rowsPerPage, page]);

  const handleRowsPerPageChange = (rowperpage) => {
    setRowsPerPage(rowperpage);
    setPage(0);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const formatDate = (dateToFormat) => {
    if (dateToFormat) {
      const date = new Date(dateToFormat);
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    } else {
      return "";
    }
  };

  const handleDenial = (data: any, status: string) => {
    setUserId(data._id);
    setDenyDialog(true);
  };

  const handleUpdate = (data: any, status: string) => {
    const id = data?._id;
    navigate(`/enterprise/${id}`);
  };

  const getInitials = (name) => {
    return name
      .split(" ")
      .map((word) => word.charAt(0))
      .join("")
      .toUpperCase();
  };

  const formatPendingAffiliates = acceptedRequests.map((row) => {
    let imageUrl = row?.user?.profilePicture || "";
    if (imageUrl.includes("?")) {
      imageUrl = imageUrl.split("?")[0];
    }
    return {
      id: row._id,
      name: (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundImage: "linear-gradient(to right, rgba(85, 105, 255, 1), rgba(30, 136, 229, 1), rgba(52, 163, 83, 1))",
                borderRadius: "50%",
                padding: "2px",
                width: "50px",
                height: "50px",
              }}
            >
              {imageUrl ? (
                <img src={imageUrl} style={{ width: "45px", height: "45px", borderRadius: "50%" }} alt="description" />
              ) : (
                <span style={{ fontSize: "18px", color: "white" }}>{getInitials(row?.name)}</span>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "left", paddingLeft: "10px" }}>{row?.name ? <strong>{row?.name}</strong> : <strong>N/A</strong>}</div>
          </div>
        </>
      ),
      email: <>{row?.email}</>,
      joinedOn: formatDate(row?.createdAt),
      action: (
        <>
          <Box sx={{ display: "flex" }}>
            <>
              <Button variant="outlined" size="small" onClick={() => handleUpdate(row, "approved")}>
                Update
              </Button>
            </>
          </Box>
        </>
      ),
    };
  });

  const handleDenySuccessChange = (denySuccess) => {
    if (denySuccess) {
      fetchAffiliates();
    }
  };

  const handleCloseDenyDialog = () => {
    setDenyDialog(false);
  };

  return (
    <>
      <SharedTable
        columns={columns}
        data={formatPendingAffiliates}
        page={page}
        tableBodyLoader={affiliateLoader}
        rowsPerPage={rowsPerPage}
        totalRows={totalCount}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        searchFilter={undefined}
        searchFilterVisible={false}
        onSearch={undefined}
        selectableRows={false}
        onRowClick={undefined}
      ></SharedTable>
      {denydialog && <DenyReason userId={userId} onClose={handleCloseDenyDialog} status="affiliate" onDenySuccessChange={handleDenySuccessChange} />}
    </>
  );
};
export default AccepetedRequests;
