import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  TextField,
  Button,
  Box,
  Container,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  useTheme,
  Grid,
  Typography,
  InputAdornment,
  DialogContentText,
  CardMedia,
  Card,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { makeStyles } from "@mui/styles";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import SuspenseLoader from "../../shared/components/SuspenseLoader";
import axios from "axios";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import SharedTable from "../../shared/components/Table";
import DialogComponent from "../../shared/components/Dialog";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import {
  createPlan,
  createService,
  deletePlan,
  generatePresignedUrl,
  getServiceList,
  getSubscriptionPlans,
  updatePlan,
  updateService,
} from "../../logic/apis/api";
import { notify } from "../../shared/components/toaster/Toaster";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  image: {
    maxWidth: 50,
    maxHeight: 50,
  },
  card: {
    marginBottom: 20,
    width: 500,
    height: 130,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  media: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
});

const statuses = [
  { label: "Operational", value: "Operational" },
  { label: "Outage", value: "Outage" },
];

const columns = [
  { id: "title", label: "Title", minWidth: 170 },
  { id: "description", label: "Description", minWidth: 170 },
  { id: "createdAt", label: "Created At", minWidth: 170 },
  { id: "action", label: "Action", minWidth: 170 },
];

const Services = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isEditing, setIsEditing] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [presignedLoader, setPresignedLoader] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedData, setSelectedData] = useState(null);
  const [openPlanStatus, setOpenPlanStatus] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [addPlanLoader, setAddPlanLoader] = useState(false);
  const [planLoader, setPlanLoader] = useState(true);
  const [updatePlanLoader, setUpdatePlanLoader] = useState(false);
  const [file, setFile] = useState<any>("");
  const [presignedUrl, setPresignedUrl] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const [profileImgError, setProfileImgError] = useState<boolean>(false);
  const [displayHistoricInfo, setDisplayHistoricInfo] =
    useState<boolean>(false);

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      description: "",
      title: "",
      displayHistoricalData: false,
      status: "",
    },
  });

  const fetchServices = async () => {
    try {
      const response = await getServiceList();
      setPlanLoader(false);
      setProducts(response.services);
      //       setTotalCount(response.totalCount);
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };

  useEffect(() => {
    fetchServices();
  }, []);

  const handleDelete = async () => {
    try {
      const result = await deletePlan(deleteId);
      if (result) {
        setIsDeleting(true);
        setOpenDelete(false);
        fetchServices();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setOpenDelete(false);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const onSubmit = async (data) => {
    console.log(data);
    if (isEditing) {
      setUpdatePlanLoader(true);
      let updatePayload = {
        serviceId: selectedData.planId,
        description: data.description,
        title: data.title,
        displayHistoricalData:
          data?.displayHistoricalData === "true" ? true : false,
        status: data.status,
      };
      try {
        const result = await updateService(updatePayload);
        if (result) {
          setUpdatePlanLoader(false);
          setOpen(false);
          setIsDeleting(false);
          setOpenPlanStatus(true);
          fetchServices();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      setAddPlanLoader(true);
      let payload = {
        title: data.title,
        description: data.description,
        displayHistoricalData:
          data?.displayHistoricalData === "true" ? true : false,
        status: data.status,
      };
      try {
        const result = await createService(payload);
        if (result) {
          setOpen(false);
          setAddPlanLoader(false);
          setIsDeleting(false);
          setOpenPlanStatus(true);
          fetchServices();
        }
      } catch (error) {
        setAddPlanLoader(false);
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleEditClick = (row) => {
    let payload = {
      planId: row._id,
    };

    let editData = products.filter((e) => e._id === row._id)[0];
    console.log(editData);
    let initial_values = {
      title: editData.title,
      description: editData.description,
      displayHistoricData: editData.displayHistoricalData,
      status: editData.status,
    };
    reset(initial_values);
    setIsEditing(true);
    setSelectedData(payload);
    setOpen(true);
  };

  const handleDeleteClick = (row) => {
    setDeleteId(row._id);
    setOpenDelete(true);
  };

  const handleOpen = () => {
    setOpen(true);
    setProfileImg(null);
    setIsEditing(false);
    reset({});
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedData(null);
    setOpenDelete(false);
  };

  const handlePlanStatusClose = () => {
    setOpenPlanStatus(false);
    setUpdatePlanLoader(false);
    setAddPlanLoader(false);
  };

  const formatDate = (dateToFormat) => {
    const date = new Date(dateToFormat);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  const formattedData = products.map((row) => {
    return {
      id: row._id,
      title: row?.title ? row.title : "N/A",
      description: row?.description ? row.description : "",
      createdAt: formatDate(row?.createdAt),
      action: (
        <>
          <IconButton
            sx={{
              "&:hover": {
                background: theme.colors.primary.lighter,
              },
              color: theme.palette.primary.main,
            }}
            color="inherit"
            size="small"
            onClick={() => handleEditClick(row)}
          >
            <EditTwoToneIcon fontSize="small" sx={{ color: "#0481D9" }} />
          </IconButton>
        </>
      ),
    };
  });

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (rowperpage) => {
    const newRowsPerPage = parseInt(rowperpage, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset page when changing rows per page
  };

  return (
    <Container component="main">
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ ms: 2, mt: 2 }}
      >
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            List of Services
          </Typography>
        </Grid>
        <Grid item>
          <Button
            sx={{ my: 2 }}
            variant="contained"
            onClick={handleOpen}
            startIcon={<AddTwoToneIcon fontSize="small" />}
          >
            Add Service
          </Button>
        </Grid>
      </Grid>
      <SharedTable
        columns={columns}
        data={formattedData}
        page={page}
        tableBodyLoader={planLoader}
        rowsPerPage={rowsPerPage}
        totalRows={totalCount}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        searchFilter={undefined}
        searchFilterVisible={false}
        onSearch={undefined}
        selectableRows={false}
        onRowClick={undefined}
      ></SharedTable>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ overflowX: "hidden" }}
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={{ padding: "16px 24px 0px 24px" }}
        >
          {isEditing ? "Update Service" : "Add Service"}
        </DialogTitle>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ width: 550 }}
        >
          <DialogContent>
            <Box
              sx={{
                maxHeight: "calc(100vh - 250px)",
                overflowY: "auto",
                maxWidth: "100%",
              }}
            >
              <div>
                <Controller
                  name="title"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Title is required",
                    pattern: {
                      value:
                        /^(?![\s!@#$%^&*()_+=[\]{}|\\;:'",.<>/?-])(?![0-9])[A-Za-z0-9\s!@#$%^&*()_+=[\]{}|\\;:'",.<>/?-]*$/,
                      message: "Please enter a valid title",
                    },
                    maxLength: {
                      value: 100,
                      message: "Max length exceeded",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Title"
                      margin="normal"
                      fullWidth
                      error={!!errors.title}
                      helperText={errors?.title?.message}
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  control={control}
                  name="description"
                  defaultValue=""
                  rules={{
                    required: "Description is required",
                    pattern: {
                      value:
                        /^(?![\s!@#$%^&*()_+=[\]{}|\\;:'",.<>/?-])(?![0-9])[A-Za-z0-9\s!@#$%^&*()_+=[\]{}|\\;:'",.<>/?-]*$/,
                      message: "Please enter a valid description",
                    },
                    maxLength: {
                      value: 200,
                      message: "Max length exceeded",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Description"
                      margin="normal"
                      fullWidth
                      error={!!errors.description}
                      helperText={errors.description?.message}
                    />
                  )}
                />
              </div>
              <div>
                <FormControl fullWidth sx={{ pb: 1 }} error={!!errors.status}>
                  <InputLabel id="demo-multiple-chip-label">Status</InputLabel>
                  <Controller
                    name="status"
                    control={control}
                    rules={{ required: "Status is required" }} // Add validation rules
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        input={<OutlinedInput label="Status" />}
                      >
                        {statuses.map((tokenModel) => (
                          <MenuItem
                            key={tokenModel.value}
                            value={tokenModel.value}
                          >
                            {tokenModel.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.status && (
                    <FormHelperText>{errors.status.message}</FormHelperText>
                  )}{" "}
                  {/* Display error message if status is invalid */}
                </FormControl>
              </div>
              <Grid item xs={12} md={12}>
                <FormLabel id="isActive">Display Historic Data</FormLabel>
                <RadioGroup
                  row
                  aria-label="isActive"
                  name="isActive"
                  value={displayHistoricInfo ? "true" : "false"}
                  onChange={(e) =>
                    setDisplayHistoricInfo(e.target.value === "true")
                  }
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio {...register("displayHistoricalData")} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio {...register("displayHistoricalData")} />}
                    label="No"
                  />
                </RadioGroup>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              type="submit"
              variant="contained"
              disabled={addPlanLoader || updatePlanLoader}
            >
              Submit
            </Button>
          </DialogActions>
        </Box>
        {(presignedLoader || updatePlanLoader || addPlanLoader) && (
          <SuspenseLoader left="0%" />
        )}
      </Dialog>

      <Dialog
        open={openPlanStatus}
        onClose={handlePlanStatusClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          display="flex"
          justifyContent="flex-end"
          p={1}
          sx={{ overflow: "hidden" }}
        >
          <IconButton
            edge="end"
            color="primary"
            onClick={handlePlanStatusClose}
            aria-label="close"
          >
            <CancelIcon sx={{ fontSize: 30, color: "#0481D9" }} />
          </IconButton>
        </Box>

        <DialogContent sx={{ width: 324, height: 240 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CheckCircleIcon color="success" sx={{ fontSize: 70, m: 2 }} />
            <DialogContentText
              id="alert-dialog-description"
              sx={{ color: "black" }}
            >
              <strong>
                Service{" "}
                {isDeleting ? "Deleted" : isEditing ? "Updated" : "Created"}{" "}
                Successfully
              </strong>
            </DialogContentText>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openDelete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: 400, height: 300 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <DeleteIcon
              sx={{ fontSize: 70, m: 2, color: "rgba(204, 43, 53, 1)" }}
            />
            <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>
              Delete
            </Typography>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ m: 2, fontWeight: 600 }}
            >
              Are you sure you want to delete this Plan?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ background: "rgba(204, 43, 53, 1)" }}
            onClick={handleDelete}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Services;
