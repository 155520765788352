import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { FIREBASE_CONFIG, VAPID_KEY } from "../constant";

const firebaseConfig = FIREBASE_CONFIG;

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getTokens = async (setTokenFound: any) => {
  return getToken(messaging, {
    vapidKey: VAPID_KEY,
  })
    .then((currentToken: any) => {
      if (currentToken) {
        localStorage.setItem("firebaseToken", currentToken);
        setTokenFound(true);
      } else {
        setTokenFound(false);
      }
    })
    .catch((err: any) => {
      console.error("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload: any) => {
      resolve(payload);
    });
  });
