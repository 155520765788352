export interface AdminLoginType {
  email: string;
  password: string;
}
export interface SettingType {
  referralFromBonus: number;
  referralToBonus: number;
}
export interface PlanType {
  name: string;
  description: string;
  price: number;
  recurring?: boolean;
  planImage: string;
  renewalPeriod?: string;
  renewalNumber?: number;
  planHours: number;
  planRate?: number;
}

export interface ModelType {
  modelId?: string;
  modelName: string;
  description: string;
  modelIdentity: string;
  inputTokenPrice: number;
  outputTokenPrice: number;
  numberOfTokens: number;
  isActive?: boolean;
  isDefault?: boolean;
}

export interface ParamType {
  page: number;
  limit: number;
  search?: string;
}

export interface ProfileType {
  name: string;
  profilePicture: string;
}

export interface PromptType {
  prompt_key?: string;
  prompt_name: string;
  prompt: string;
  variables: string;
  model_identities?: [];
  variable_info: string;
  is_active?: boolean;
  prompt_id?: string;
}
export interface EnterpriseType {
  organization: string;
  organizationSize: number;
  phone: string;
  countryCode: string;
  email: string;
  name: string;
  userRole: string;
  techStack: string;
  organizationType: string;
  descriptionAboutArcadia: string;
}
export interface ReviewTemplateType {
  id: string;
  status: string;
  rejectReason?: string;
}

export interface ChangePasswordType {
  confirmPassword: string;
  password: string;
  oldPassword: string;
}
interface VariableValue {
  key: string;
  value: string;
}
export interface PreviewPromptType {
  variablesValues?: VariableValue[];
  prompt: string;
}
export interface TemplateType {
  _id: string;
  title: string;
  image: string;
  stages: {
    suggestions: string[];
    name: string;
  }[];
  isPublic: boolean;
  status: string;
  user: {
    _id: string;
    name: string;
    email: string;
  };
  categories: {
    _id: string;
    name: string;
    templatesCount: number;
    description: string;
    imageUrl: string;
  }[];
  userRole: string;
}

export interface presignedUrlType {
  fileTag: string;
  fileType: string;
}

export enum TokenType {
  CHECK_STAGE_QUESTIONS = "check_stage_questions",
  STAGE_GENERATION = "stage_generation",
  STAGES_CHAT = "stages_chat",
  FETCH_CLIENT_PERSONA = "fetch_client_persona",
  ANSWER_CLIENT_QUESTIONS = "answer_client_questions",
  PROMPT_QUESTION = "prompt_question",
  FETCH_SUMMARY = "fetch_summary",
  GET_POST_QUERY = "get_post_query",
  STAGES_REGENERATE = "stages_regenerate",
  ADD_AGENDA = "add_agenda",
  GENERATE_ACTION_ITEM = "generate_action_item",
  WEB_SEARCH = "web_search",
  QUESTIONS_CHAT = "questions_chat",
  AGENDA_DESCRIPTION = "agenda_description",
  QUESTION_REGENERATE = "question_regenerate",
}

export enum TokenModel {
  GPT3_5 = "openai-gpt-3.5",
  GPT3_5_TURBO = "openai-gpt-3.5-turbo",
  GPT3_5_TURBO_16K = "openai-gpt-3.5-turbo-16k",
  GPT4 = "openai-gpt-4",
  GPT4_32K = "openai-gpt-4-32k",
  MISTRALAI = "mistralai",
}
