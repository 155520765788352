import { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Avatar, Box, Button, Hidden, lighten, Popover, Typography } from "@mui/material";

import { styled } from "@mui/material/styles";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import { adminLogout, getProfileDetails, updateProfileDetails } from "../../../../logic/apis/api";
import { useUser } from "../../../../shared/contexts/UserContext";

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.primary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.primary.main, 0.5)}
`
);

function HeaderUserbox() {
  const { user, updateUser } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userProfile = await getProfileDetails();
        if (userProfile.user) {
          updateUser({ name: userProfile.user.name, profilePic: userProfile.user.profilePicture });
        }
        if (userProfile.user.role[0] !== "admin") {
          localStorage.clear();
          navigate("/");
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, []);

  const updateUserData = async (userData) => {
    try {
      const response = await updateProfileDetails(userData);
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  useEffect(() => {
    const firebaseToken = localStorage.getItem("firebaseToken");
    updateUserData({ firebaseToken });
  }, []);

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  const logout = async () => {
    try {
      const resp = await adminLogout();
      if (resp) {
        window.localStorage.clear();
        navigate("/");
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <UserBoxButton color="primary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={user.name} src={user.profilePic} />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1" color="primary">
              {user.name}
            </UserBoxLabel>
            <UserBoxDescription variant="body2" color="primary">
              {"Admin"}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ top: 60 }}
      >
        <Box sx={{ m: 1 }}>
          <Button color="primary" onClick={logout} fullWidth>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
