import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import './Toaster.css' // Import your custom styles

const Toaster = () => {
  return <ToastContainer position="top-right" autoClose={4000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />;
};

export const notify = {
  success: (message: any) => {
    const existingToast = toast.isActive("success-toast");
    if (!existingToast) {
      toast.success(message, { toastId: "success-toast" });
    }
  },
  error: (message: any) => {
    const existingToast = toast.isActive("error-toast");
    if (!existingToast) {
      toast.error(message, { toastId: "error-toast" });
    }
  },
  info: (message: any) => {
    const existingToast = toast.isActive("info-toast");
    if (!existingToast) {
      toast.info(message, { toastId: "info-toast" });
    }
  },
};

export default Toaster;
