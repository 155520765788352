import { Button, Card } from "@mui/material";
import { useEffect, useState } from "react";
import { allAffiliates, allTransactions, updateTransaction } from "../../logic/apis/api";
import SharedTable from "../../shared/components/Table";
import { Box } from "@mui/system";
import DenyReason from "./denyReason";
import { useNavigate } from "react-router";

const columns = [
  { id: "name", label: "Name", width: "300px" },
  { id: "email", label: "Email", width: "250px" },
  { id: "joinedOn", label: "Requested On", width: "250px" },
  { id: "amount", label: "Claim Amount", width: "200px" },
  { id: "action", label: "Action", width: "250px" },
];

const SettlementUserRequest = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [affiliates, setAffiliates] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [affiliateLoader, setAffiliateLoader] = useState(true);
  const [denydialog, setDenyDialog] = useState(false);
  const [userId, setUserId] = useState();
  const navigate = useNavigate();

  const fetchAffiliates = async () => {
    try {
      const limit = rowsPerPage;
      const response = await allTransactions(page, limit, "PENDING");
      setAffiliateLoader(false);
      setAffiliates(response?.data?.transactions);
      setTotalCount(response?.data?.transactionsCount);
    } catch (error) {
      console.error("Error fetching subscribed users:", error);
    }
  };

  useEffect(() => {
    fetchAffiliates();
  }, [rowsPerPage, page]);

  const handleRowsPerPageChange = (rowperpage) => {
    setRowsPerPage(rowperpage);
    setPage(0);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleDenial = (data: any, status: string) => {
    setUserId(data?._id);
    setDenyDialog(true);
  };

  const formatDate = (dateToFormat) => {
    if (dateToFormat) {
      const date = new Date(dateToFormat);
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    } else {
      return "";
    }
  };

  const handleApproval = async (data: any, status: string) => {
    const id = data?._id;
    const payload = {
      transactionId: id,
      status: "SETTLED",
      remark: "",
    };
    const resp = await updateTransaction(payload);
    if (resp) {
      fetchAffiliates();
    }
  };

  const formatPendingAffiliates = (affiliates || []).map((row) => {
    let imageUrl = row?.user?.profilePicture || "";
    if (imageUrl.includes("?")) {
      imageUrl = imageUrl.split("?")[0];
    }
    return {
      id: row._id,
      name: (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            {imageUrl ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundImage: "linear-gradient(to right, rgba(85, 105, 255, 1), rgba(30, 136, 229, 1), rgba(52, 163, 83, 1))",
                  borderRadius: "50%",
                  padding: "2px",
                  width: "50px",
                  height: "50px",
                }}
              >
                <img src={imageUrl} style={{ width: "45px", height: "45px", borderRadius: "50%" }} alt="description" />
              </div>
            ) : null}
            <div style={{ display: "flex", flexDirection: "column", alignItems: "left", paddingLeft: "10px" }}>{row?.user?.name ? <strong>{row?.user?.name}</strong> : <strong>N/A</strong>}</div>
          </div>
        </>
      ),
      email: <>{row?.user?.email}</>,
      joinedOn: formatDate(row?.createdAt),
      amount: <>{row?.amount}</>,
      action: (
        <>
          <Box sx={{ display: "flex" }}>
            <>
              <Button sx={{ mr: 1 }} variant="outlined" size="small" onClick={() => handleDenial(row, "rejected")} color="secondary">
                Deny
              </Button>

              <Button variant="outlined" size="small" onClick={() => handleApproval(row, "approved")}>
                Approve
              </Button>
            </>
          </Box>
        </>
      ),
    };
  });

  const handleDenySuccessChange = (denySuccess) => {
    if (denySuccess) {
      fetchAffiliates();
    }
  };

  const handleCloseDenyDialog = () => {
    setDenyDialog(false);
  };

  return (
    <>
      <SharedTable
        columns={columns}
        data={formatPendingAffiliates}
        page={page}
        tableBodyLoader={affiliateLoader}
        rowsPerPage={rowsPerPage}
        totalRows={totalCount}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        searchFilter={undefined}
        searchFilterVisible={false}
        onSearch={undefined}
        selectableRows={false}
        onRowClick={undefined}
      ></SharedTable>
      {denydialog && <DenyReason userId={userId} onClose={handleCloseDenyDialog} status="transaction" onDenySuccessChange={handleDenySuccessChange} />}
    </>
  );
};
export default SettlementUserRequest;
