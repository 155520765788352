import { Box, Button, Grid, Paper, Tab, Tabs, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { deleteEnterpriseRequest, getAffiliateUserDetails, getCustomRequestsById, getSubscriberListById, updateAffiliate, updateCustomRequests } from "../../logic/apis/api";
import { Controller, useForm } from "react-hook-form";
import useStyles from "../../styles/theme/styles";
import DenyReason from "../earnProgram/denyReason";
interface RouteProps {
  id: string;
  email: string;
  status: string;
}
const EnterpriseDetail = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const { id } = useParams();
  const [userData, setUserData] = useState<any>();
  const [denydialog, setDenyDialog] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      numberOfUsers: 0,
      pricePerUser: 0,
      numberOfTokensPerUser: 0,
    },
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDataById = async () => {
      try {
        const response = await getCustomRequestsById(id);
        setUserData(response);
        setValue("numberOfUsers", response?.numberOfUsers || 0);
        setValue("pricePerUser", response?.pricePerUser || 0);
        setValue("numberOfTokensPerUser", response?.numberOfTokensPerUser || 0);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    fetchUserDataById();
  }, []);

  const handleApprove = async (data) => {
    const payload = {
      requestId: id,
      status: "approved",
      pricePerUser: parseInt(data?.pricePerUser, 10),
      rejectReason: "",
      numberOfUsers: parseInt(data?.numberOfUsers, 10),
      numberOfTokensPerUser: parseInt(data?.numberOfTokensPerUser, 10),
    };
    const resp = await updateCustomRequests(payload);
    if (resp?.message) {
      navigate("/enterprise");
    }
  };

  const handleDisableEnterprise = async () => {
    try {
      const result = await deleteEnterpriseRequest(id);
      if (result) {
        navigate("/enterprise");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDenial = () => {
    setDenyDialog(true);
  };

  const handleCloseDenyDialog = () => {
    setDenyDialog(false);
  };

  const handleDenySuccessChange = (denySuccess) => {
    if (denySuccess) {
      navigate("/enterprise");
    }
  };

  return (
    <>
      <Box sx={{ m: 2 }}>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" style={{ fontSize: 30, fontWeight: 700 }}>
              Details of {userData?.name || "N/A"}
            </Typography>
            <Button sx={{ m: 1 }} variant="outlined" size="small" onClick={() => handleDenial()} color="secondary">
              Deny
            </Button>
          </Box>
          <Paper elevation={3} sx={{ padding: 1.5 }}>
            <Grid container spacing={2} gap={3}>
              <Grid item xs={2} display="flex" flexDirection="column">
                <Box flexGrow={1} display="flex" flexDirection="column" sx={{ pe: 2 }}>
                  <Paper style={{ marginBottom: "16px", height: 164, width: 164, display: "flex", alignItems: "center", justifyContent: "center", fontSize: 48 }}>
                    {userData?.name?.[0]?.toUpperCase() || ""}
                  </Paper>
                  <Typography variant="h6" style={{ fontSize: 16, fontWeight: 700, textAlign: "center" }}>
                    {userData?.name || "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={9} sx={{ display: "flex", mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={5} md={6}>
                    <Box sx={{ display: "flex", my: 1 }}>
                      <Typography variant="body1" sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}>
                        Name:
                      </Typography>
                      <Typography variant="body1">{userData?.name || "N/A"}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", my: 1 }}>
                      <Typography variant="body1" sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}>
                        Email Address:
                      </Typography>
                      <Typography variant="body1">{userData?.email || "N/A"}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", my: 1 }}>
                      <Typography variant="body1" sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}>
                        Company Name:
                      </Typography>
                      <Typography variant="body1">{userData?.organization || "N/A"}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", my: 1 }}>
                      <Typography variant="body1" sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}>
                        Date of joining:
                      </Typography>
                      <Typography variant="body1">{new Date(userData?.createdAt).toLocaleDateString("en-GB")}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", my: 1 }}>
                      <Typography variant="body1" sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}>
                        Phone :
                      </Typography>
                      <Typography variant="body1">{userData?.phone || "N/A"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Box sx={{ display: "flex", my: 1 }}>
                      <Typography variant="body1" sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}>
                        Role:
                      </Typography>
                      <Typography variant="body1">{userData?.userRole || "N/A"}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", my: 1 }}>
                      <Typography variant="body1" sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}>
                        Organization size:
                      </Typography>
                      <Typography variant="body1">{userData?.organizationSize || "0"}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", my: 1 }}>
                      <Typography variant="body1" sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}>
                        Tech Stack:
                      </Typography>
                      <Typography variant="body1"> {userData?.techSack ? JSON.parse(userData.techSack).join(", ") : "N/A"}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", my: 1 }}>
                      <Typography variant="body1" sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}>
                        Description:
                      </Typography>
                      <Typography variant="body1">{userData?.descriptionAboutArcadia || "N/A"}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Box>

        <Box sx={{ width: "100%", mt: 2 }}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h6" style={{ fontSize: 26, fontWeight: 700 }}>
              Enterprise Plan Settings
            </Typography>
            <form onSubmit={handleSubmit(handleApprove)}>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <Controller
                    name="numberOfUsers"
                    control={control}
                    defaultValue={userData?.numberOfUsers}
                    rules={{
                      required: "Number of Users is required",
                      pattern: {
                        value: /^[1-9]\d*\.?\d*$/,
                        message: "Please enter a valid users",
                      },
                    }}
                    render={({ field }) => <TextField {...field} label="Number of users" margin="normal" fullWidth error={!!errors.numberOfUsers} helperText={errors?.numberOfUsers?.message} />}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="pricePerUser"
                    control={control}
                    defaultValue={userData?.pricePerUser}
                    rules={{
                      required: "Price is required",
                      pattern: {
                        value: /^[1-9]\d*\.?\d*$/,
                        message: "Please enter a valid cost per seat",
                      },
                    }}
                    render={({ field }) => <TextField {...field} label="Cost per seat ($)" margin="normal" fullWidth error={!!errors.pricePerUser} helperText={errors?.pricePerUser?.message} />}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    name="numberOfTokensPerUser"
                    control={control}
                    defaultValue={userData?.numberOfTokensPerUser}
                    rules={{
                      required: "Number of tokens is required",
                      pattern: {
                        value: /^[1-9]\d*\.?\d*$/,
                        message: "Please enter a valid tokens",
                      },
                    }}
                    render={({ field }) => (
                      <TextField {...field} label="Arc Tokens per seat" margin="normal" fullWidth error={!!errors.numberOfTokensPerUser} helperText={errors?.numberOfTokensPerUser?.message} />
                    )}
                  />
                </Grid>
              </Grid>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                {userData?.status == "approved" && (
                  <Box sx={{ display: "flex", justifyContent: "end", m: 2 }}>
                    <Button variant="contained" color="secondary" onClick={handleDisableEnterprise}>
                      Disable
                    </Button>
                  </Box>
                )}

                <Box sx={{ display: "flex", justifyContent: "end", m: 2 }}>
                  <Button type="submit" variant="contained" color="primary" disabled={!isDirty || Object.keys(errors).length > 0} classes={{ disabled: classes.disabledButton }}>
                    Approve
                  </Button>
                </Box>
              </Box>
            </form>
          </Paper>
        </Box>
      </Box>
      {denydialog && <DenyReason userId={id} onClose={handleCloseDenyDialog} status="enterprise" onDenySuccessChange={handleDenySuccessChange} />}
    </>
  );
};

export default EnterpriseDetail;
