import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { SyntheticEvent, useEffect, useState } from "react";
import OpenRequests from "./openeRequests";
import RejectedRequests from "./rejectedRequests";
import AccepetedRequests from "./acceptedRequests";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { EnterpriseType } from "../../shared/utilities/Types/types";
import { addEnterpriseRequest, customRequests } from "../../logic/apis/api";
import { countryCodes } from "./countrycode";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const statuses = [
  { label: "Business", value: "Business" },
  { label: "Start-up", value: "Start-up" },
  { label: "Education", value: "Education" },
  { label: "Non-profit", value: "Non-profit" },
];

const Enterprise = () => {
  const [value, setValue] = useState(0);
  const [openEnterpriseDialog, setOpenEnterpriseDialog] = useState(false);
  const theme = useTheme();
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const schema = Yup.object().shape({
    organization: Yup.string()
      .required("Please enter organization.")
      .matches(/^[^\s][\w\s!@#$%^&*()_+=[\]{}|\\;:'",.<>/?-]*$/, "Please enter a valid organization name.")
      .max(50, "Max length exceeded"),

    organizationSize: Yup.string()
      .required("Please enter organization size.")
      .matches(/^\d+$/, "Please enter a  valid organisation size.")
      .min(1, "Please enter a valid organization size (more than 0)."),

    phone: Yup.string()
      .required("Please enter phone number.")
      .matches(/^\d{3,}$/, "Please enter a valid phone number with at least 3 digits."),

    countryCode: Yup.string().required("Please enter country code.").matches(/^\d+$/, "Please enter a valid country code."),

    email: Yup.string().required("Please enter email.").email("Please enter a valid email address."),

    name: Yup.string()
      .required("Please enter name.")
      .matches(/^[^\s][\w\s!@#$%^&*()_+=[\]{}|\\;:'",.<>/?-]*$/, "Please enter a valid name."),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    defaultValues: {
      organization: "",
      organizationSize: 0,
      phone: "",
      countryCode: "",
      email: "",
      name: "",
      userRole: "",
      techStack: "",
      organizationType: "",
      descriptionAboutArcadia: "",
    },
  });

  const formSubmit = async (data) => {
    if (data) {
      const enterpriseData: EnterpriseType = {
        organization: data.organization,
        organizationSize: Number(data.organizationSize),
        name: data?.name,
        email: data.email,
        countryCode: data.countryCode,
        phone: data.phone,
        userRole: data.userRole,
        techStack: data.techStack ? JSON.stringify(data.techStack.split(",").map((item) => item.trim())) : "",
        organizationType: "",
        descriptionAboutArcadia: "",
      };
      console.log(enterpriseData);
      try {
        const response = await addEnterpriseRequest(enterpriseData);
        if (response?.message) {
          //           handleChange(null, 0);
          setShouldRefetch(true);
        }
      } catch (error) {
        console.error("Error fetching subscribed users:", error);
      }
      handleClose();
    }
  };

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    console.log("new");
    setValue(newValue);
  };

  const handleOpen = () => {
    setOpenEnterpriseDialog(true);
    reset({});
  };

  const handleClose = () => {
    setOpenEnterpriseDialog(false);
  };

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ ms: 2, mt: 2 }}>
        <Grid item>
          <Typography sx={{ mb: 2, ml: 2 }} variant="h3" component="h3" gutterBottom>
            Enterprise Requests
          </Typography>
        </Grid>
        <Grid item sx={{ display: "flex" }}>
          <Button sx={{ my: 2, ml: "auto" }} variant="contained" onClick={handleOpen} startIcon={<AddTwoToneIcon fontSize="small" />}>
            Add Request
          </Button>
        </Grid>
      </Grid>
      <Card>
        <Dialog open={openEnterpriseDialog} onClose={handleClose} scroll="paper" aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description" sx={{ overflowX: "hidden" }}>
          <DialogTitle
            id="scroll-dialog-title"
            sx={{
              padding: "16px 24px 0px 24px",
              color: theme.colors.alpha.black[100],
              fontFamily: "TomatoGroteskMedium",
            }}
          >
            {"Add Request"}
          </DialogTitle>

          <Box component="form" onSubmit={handleSubmit(formSubmit)} noValidate={true} sx={{ width: 550 }}>
            <DialogContent>
              <Box
                sx={{
                  maxHeight: "calc(100vh - 250px)",
                  overflowY: "auto",
                  maxWidth: "100%",
                }}
              >
                <div>
                  <TextField {...register("organization")} label="Organisation" margin="normal" fullWidth />
                  {errors?.organization && <Typography sx={{ color: theme.colors.error.light }}>{errors?.organization?.message}</Typography>}
                </div>
                <div>
                  <TextField {...register("organizationSize")} label="Organisation Size" margin="normal" fullWidth />
                  {errors?.organizationSize && <Typography sx={{ color: theme.colors.error.light }}>{errors?.organizationSize?.message}</Typography>}
                </div>
                {/* <div>
                  <TextField {...register("countryCode")} label="Country code" margin="normal" fullWidth />
                  {errors?.countryCode && <Typography sx={{ color: theme.colors.error.light }}>{errors?.countryCode?.message}</Typography>}
                </div> */}
                <FormControl fullWidth sx={{ pb: 1 }}>
                  <InputLabel id="demo-multiple-chip-label">Country code</InputLabel>
                  <Controller
                    name="countryCode"
                    control={control}
                    render={({ field }) => (
                      <Select {...field} labelId="demo-multiple-chip-label" id="demo-multiple-chip" input={<OutlinedInput label="Country code" />}>
                        {countryCodes.map((tokenModel: any) => (
                          <MenuItem key={tokenModel.name} value={tokenModel.code}>
                            {tokenModel.name} - {tokenModel.code}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <div>
                  <TextField {...register("phone")} label="Phone" margin="normal" fullWidth />
                  {errors?.phone && <Typography sx={{ color: theme.colors.error.light }}>{errors?.phone?.message}</Typography>}
                </div>
                <div>
                  <TextField {...register("name")} label="Name" margin="normal" fullWidth />
                  {errors?.name && <Typography sx={{ color: theme.colors.error.light }}>{errors?.name?.message}</Typography>}
                </div>
                <div>
                  <TextField {...register("email")} label="Email" margin="normal" fullWidth />
                  {errors?.email && <Typography sx={{ color: theme.colors.error.light }}>{errors?.email?.message}</Typography>}
                </div>
                <div>
                  <TextField {...register("userRole")} label="Role" margin="normal" fullWidth />
                  {errors?.userRole && <Typography sx={{ color: theme.colors.error.light }}>{errors?.userRole?.message}</Typography>}
                </div>
                <div>
                  <TextField {...register("techStack")} label="Tech Stack" margin="normal" fullWidth />
                  {errors?.techStack && <Typography sx={{ color: theme.colors.error.light }}>{errors?.techStack?.message}</Typography>}
                </div>
                <FormControl fullWidth sx={{ pb: 1 }}>
                  <InputLabel id="demo-multiple-chip-label">Organisation Type</InputLabel>
                  <Controller
                    name="organizationType"
                    control={control}
                    render={({ field }) => (
                      <Select {...field} labelId="demo-multiple-chip-label" id="demo-multiple-chip" input={<OutlinedInput label="Organization Type" />}>
                        {statuses.map((tokenModel) => (
                          <MenuItem key={tokenModel.value} value={tokenModel.value}>
                            {tokenModel.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <div>
                  <TextField {...register("descriptionAboutArcadia")} label="Description" margin="normal" fullWidth />
                  {errors?.descriptionAboutArcadia && <Typography sx={{ color: theme.colors.error.light }}>{errors?.descriptionAboutArcadia?.message}</Typography>}
                </div>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>

              <Button type="submit" variant="contained">
                {"Add"}
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
        <Divider />
        <CardContent>
          <Box sx={{ width: "100%" }}>
            <Tabs variant="scrollable" scrollButtons="auto" textColor="primary" indicatorColor="primary" value={value} onChange={handleChange} aria-label="core entity">
              <Tab label="Open Requests" {...a11yProps(0)} />
              <Tab label="Rejected Requests" {...a11yProps(1)} />
              <Tab label="Accepted Requests" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <OpenRequests shouldRefetch={shouldRefetch} onRefetchComplete={() => setShouldRefetch(false)} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <RejectedRequests />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <AccepetedRequests />
            </TabPanel>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Enterprise;
