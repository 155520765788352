import { Card, CardContent, CardHeader, Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { SyntheticEvent, useEffect, useState } from "react";
import AffiliateProgram from "./affiliateProgram";
import ReferralProgram from "./ReferralProgram";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EarnProgram = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader title="Earn Program" />
        <Divider />
        <CardContent>
          <Box sx={{ width: "100%" }}>
            <Tabs variant="scrollable" scrollButtons="auto" textColor="primary" indicatorColor="primary" value={value} onChange={handleChange} aria-label="core entity">
              <Tab label="Affiliate Program" {...a11yProps(0)} />
              <Tab label="Referral Program" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <AffiliateProgram />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ReferralProgram />
            </TabPanel>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default EarnProgram;
