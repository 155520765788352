import { Button, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Skeleton, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getProfileDetails, updateSetting } from "../../logic/apis/api";
import useStyles from "../../styles/theme/styles";

const ReferralProgram = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [openReferralStatus, setOpenReferralStatus] = useState(false);
  const [isAffiliateActive, setIsAffiliateActive] = useState(true);
  const [isReferralActive, setIsReferralActive] = useState(true);

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    setError,
    clearErrors,
  } = useForm();

  const fetchUserProfile = async () => {
    try {
      const userProfile = await getProfileDetails();
      let initial_values = {
        referralFromBonus: userProfile?.user?.adminSettings?.referralFromBonus,
        referralToBonus: userProfile?.user?.adminSettings?.referralToBonus,
        affiliateBonus: userProfile?.user?.adminSettings?.affiliateBonus,
        affiliateActive: userProfile?.user?.adminSettings?.isFreePlanAffiliateActive,
        referralActive: userProfile?.user?.adminSettings?.isFreePlanReferralActive,
      };
      setIsAffiliateActive(userProfile?.user?.adminSettings?.isFreePlanAffiliateActive);
      setIsReferralActive(userProfile?.user?.adminSettings?.isFreePlanReferralActive);
      reset(initial_values);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const handleSubmitReferralBonus = async (data) => {
    const payload: any = {
      referralFromBonus: parseInt(data?.referralFromBonus) || "",
      referralToBonus: parseInt(data?.referralToBonus) || "",
      affiliateBonus: parseInt(data?.affiliateBonus) || "",
      isFreePlanAffiliateActive: isAffiliateActive,
      isFreePlanReferralActive: isReferralActive,
    };
    try {
      if (Number.isInteger(payload.referralFromBonus) && Number.isInteger(payload.referralToBonus)) {
        const response = await updateSetting(payload);
        setOpenReferralStatus(true);
      }
    } catch (error) {}
  };

  return (
    <Box component="form" onSubmit={handleSubmit(handleSubmitReferralBonus)}>
      <Grid container>
        <Grid item xs={6} md={8}>
          <>
            <Typography variant="h4" sx={{ mt: 1, mb: 2 }}>
              Referral Bonus
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                {loading ? (
                  <Skeleton variant="text" width="100%" height={70} />
                ) : (
                  <TextField
                    label="Referral from Bonus (%)"
                    {...register("referralFromBonus", {
                      pattern: {
                        value: /^(?:[1-9]|[1-9][0-9]|100)$/,
                        message: "Please enter a positive number",
                      },
                    })}
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    error={!!errors.referralFromBonus}
                    helperText={errors?.referralFromBonus?.message}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              {loading ? (
                <Skeleton variant="text" width="100%" height={70} />
              ) : (
                <TextField
                  label="Referral to bonus (%)"
                  {...register("referralToBonus", {
                    pattern: {
                      value: /^(?:[1-9]|[1-9][0-9]|100)$/,
                      message: "Please enter a positive number",
                    },
                  })}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  error={!!errors.referralToBonus}
                  helperText={errors?.referralToBonus?.message}
                />
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              {loading ? (
                <Skeleton variant="text" width="100%" height={70} />
              ) : (
                <TextField
                  label="Affiliate bonus tokens"
                  {...register("affiliateBonus", {
                    pattern: {
                      value: /^(?:[1-9]|[1-9][0-9]|100)$/,
                      message: "Please enter a positive number",
                    },
                  })}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  error={!!errors.affiliateBonus}
                  helperText={errors?.affiliateBonus?.message}
                />
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <FormLabel id="isActive">Affiliate Free Plan</FormLabel>
              <RadioGroup row aria-label="isActive" name="isActive" value={isAffiliateActive ? "true" : "false"} onChange={(e) => setIsAffiliateActive(e.target.value === "true")}>
                <FormControlLabel value="true" control={<Radio {...register("affiliateActive")} />} label="Active" />
                <FormControlLabel value="false" control={<Radio {...register("affiliateActive")} />} label="Not Active" />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormLabel id="isActiveR">Referral Free Plan</FormLabel>
              <RadioGroup row aria-label="isActiveR" name="isActiveR" value={isReferralActive ? "true" : "false"} onChange={(e) => setIsReferralActive(e.target.value === "true")}>
                <FormControlLabel value="true" control={<Radio {...register("referralActive")} />} label="Active" />
                <FormControlLabel value="false" control={<Radio {...register("referralActive")} />} label="Not Active" />
              </RadioGroup>
            </Grid>
          </>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: 2,
        }}
      >
        <Button variant="contained" type="submit" disabled={!isDirty || Object.keys(errors).length > 0} classes={{ disabled: classes.disabledButton }}>
          Update
        </Button>
      </Grid>
    </Box>
  );
};
export default ReferralProgram;
