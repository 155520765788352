import { Grid, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Label from "../../shared/components/Label";
import { IconButton } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import DoneIcon from '@mui/icons-material/Done';
import { addArcTokens } from "../../logic/apis/api";
interface LocationState {
  page: any;
}

const DetailComponent = ({ sponsorData }) => {
  type Color = "error" | "info" | "secondary";
  const { id } = useParams();
  const [editedTokens, setEditedTokens] = useState(sponsorData?.totalTokens || 0);
  const getStatusLabel = (status: "active" | "inactive" | "canceled"): JSX.Element => {
    let color = "";
    let text = "";
    switch (status) {
      case "active":
        text = "Active";
        color = "success";
        break;
      case "inactive":
        text = "Inactive";
        color = "secondary";
        break;
      case "canceled":
        text = "Cancelled";
        color = "error";
        break;
      default:
        color = "warning";
        text = "Inactive";
        break;
    }
    return <Label color={color as Color}>{text}</Label>;
  };

  useEffect(()=>{
    setEditedTokens(sponsorData?.totalTokens)
  },[sponsorData])

  const handleTokensChange = (event) => {
    const value = event.target.value.replace(/[^0-9]/g, '');
    setEditedTokens(value);
  };

  const handleTokensEditSubmit = async () => {
    try {
      const payload={
        userId:id,
        tokenCount: ( editedTokens - sponsorData?.totalTokens)
      }
      const response = await addArcTokens(payload);
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  const copyToClipboard = useCallback((text) => {
    navigator.clipboard.writeText(text);
  }, []);
  const navigate = useNavigate();

  return (
    <Box>
      <Typography variant="h6" style={{ fontSize: 30, fontWeight: 700 }}>
        Details of {sponsorData?.name || "N/A"}
      </Typography>

      <Paper elevation={3} sx={{ padding: 1.5 }}>
        <Grid container spacing={2} gap={3}>
          <Grid item xs={2} display="flex" flexDirection="column">
            <Box flexGrow={1} display="flex" flexDirection="column" sx={{ pe: 2 }}>
              <Paper style={{ marginBottom: "16px", height: 164, width: 164 }}>
                {sponsorData?.profilePicture && <img src={sponsorData?.profilePicture} alt="Sponsor" style={{ width: "100%", height: "100%" }} />}
              </Paper>
              <Typography variant="h6" style={{ fontSize: 16, fontWeight: 700, textAlign: "center" }}>
                {sponsorData?.name || "N/A"}
              </Typography>
              <Typography variant="body1" style={{ textAlign: "center" }}>
                {getStatusLabel(sponsorData?.status) || "N/A"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={9} sx={{ display: "flex", mt: 2 }}>
            <Grid container spacing={2} gap={5}>
              <Grid item xs={12} md={5}>
                <Box sx={{ display: "flex", my: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}>
                    Email Address:
                  </Typography>
                  <Typography variant="body1">{sponsorData?.email || "N/A"}</Typography>
                </Box>
                <Box sx={{ display: "flex", my: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}>
                    TVL:
                  </Typography>
                  <Typography variant="body1">$ {sponsorData?.tvl}</Typography>
                </Box>
                <Box sx={{ display: "flex", my: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}>
                    Total Hours:
                  </Typography>
                  <Typography variant="body1"> {Number(sponsorData?.totalHours) / 3600}hrs</Typography>
                </Box>
                <Box sx={{ display: "flex", my: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}>
                    Arcadia Tokens:
                  </Typography>
                  <TextField
                  type="text"
                  value={editedTokens}
                  onChange={handleTokensChange}
                  variant="standard"
                />
               <IconButton aria-label="delete" size="small" onClick={handleTokensEditSubmit}>
                <DoneIcon fontSize="inherit" />
                </IconButton>
                </Box>
              </Grid>
              <Box sx={{ width: "0.5px", backgroundColor: "rgba(204, 204, 204, 1)", my: 3, mx: 2 }} /> {/* Vertical line */}
              <Grid item xs={12} md={5}>
                <Box sx={{ display: "flex", my: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}>
                    Subscription Plan:
                  </Typography>
                  <Typography variant="body1">{sponsorData?.planName}</Typography>
                </Box>
                <Box sx={{ display: "flex", my: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}>
                    Subscription Status:
                  </Typography>
                  <Typography variant="body1">{sponsorData?.subscriptionStatus}</Typography>
                </Box>
                <Box sx={{ display: "flex", my: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}>
                    Created on:
                  </Typography>
                  <Typography variant="body1">{new Date(sponsorData?.subscriptionStartDate  * 1000).toLocaleString('en-GB')}</Typography>
                </Box>
                <Box sx={{ display: "flex", my: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}>
                    Plan ends on:
                  </Typography>
                  <Typography variant="body1">{new Date(sponsorData?.subscriptionEndDate * 1000).toLocaleString('en-GB')}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default DetailComponent;
