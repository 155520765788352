import React, { useEffect, useState } from "react";
import SharedTable from "../../shared/components/Table";
import {
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import useStyles from "../../styles/theme/styles";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import SuspenseLoader from "../../shared/components/SuspenseLoader";
import ReactMarkdown from "react-markdown";
import DynamicVariableForm from "./DynamicVariableForm";
import { addPrompt, getGptPrompts, getGptPromptsById, getModelDetails, previewPrompt, updatePrompt } from "../../logic/apis/api";
import { PromptType, TokenModel } from "../../shared/utilities/Types/types";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";

const columns = [
  { id: "prompt_name", label: "Prompt Name", width: "300px" },
  { id: "model", label: "Model", width: "200px" },
  { id: "variable_info", label: "Variable Info", width: "400px" },
  { id: "prompt", label: "Prompt", width: "400px" },
  { id: "action", label: "Action", width: "100px" },
];

export const TextArea = styled(TextareaAutosize)(({ theme }) => ({
  fontFamily: "TomatoGrotesk",
  fontSize: "14px",
  borderColor: "rgba(0, 0, 0, 0.23)", // Use theme color
  borderRadius: "4px",
  padding: "16.5px 14px",
  lineHeight: 1.7,
  width: "100%",
  "&:focus-visible": {
    borderColor: theme.colors.primary.main, // Change border color on focus
    outline: "none",
  },
}));

const PromptList = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [promptsList, setPromptsList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openPromptDialog, setOpenPromptDialog] = useState(false);
  const [openPromptPreviewDialog, setOpenPromptPreviewDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedRowData, setselectedRowData] = useState({
    prompt_key: "",
    prompt_name: "",
    prompt: "",
    variables: "",
    variable_info: "",
    _id: "",
  });
  const [dynamicVariables, setDynamicVariables] = useState([]);
  const [previewResposeOpenAi, setPreviewResposeOpenAi] = useState(undefined);
  // const [previewPromptApi, { data: previewPromptData, loading: previewPromptLoader }] = useMutation(PreviewEditPrompt);
  const [promptLoader, setPromptLoader] = useState(true);
  const [updatePromptLoader, setUpdatePromptLoader] = useState(false);
  const [promptDetailLoader, setPromptDetailLoader] = useState(false);
  const [previewPromptLoader, setPreviewPromptLoader] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [models, setModels] = useState([]);
  const [filters, setFilters] = useState({
    model: null,
  });
  const schema = Yup.object().shape({
    prompt_name: Yup.string()
      .required("Please enter prompt name.")
      .matches(/^[^\s][\w\s!@#$%^&*()_+=[\]{}|\\;:'",.<>/?-]*$/, "Please enter a valid prompt name.")
      .max(50, "Max length exceeded"),
    prompt: Yup.string().required("Please enter prompt."),
    model_identifer: Yup.array().required("Please select at least one model."),
    variables: isEditing ? Yup.string() : Yup.string().required("Please enter variables."),
    variable_info: isEditing ? Yup.string() : Yup.string().required("Please enter variable info."),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    defaultValues: {
      model_identifer: [],
      prompt_name: "",
      prompt: "",
      variables: "",
      variable_info: "",
    },
  });

  const createValidationSchema = (dynamicVariables) => {
    const schema = {};
    dynamicVariables.forEach((variable) => {
      schema[variable] = Yup.string().required(`Please enter ${variable}`);
    });
    return Yup.object().shape(schema);
  };

  const {
    register: registerDynamicForm,
    handleSubmit: handleDynamicForm,
    formState: dynamicFormState,
    reset: dynamicFormvalueReset,
  } = useForm({
    resolver: yupResolver(createValidationSchema(dynamicVariables)),
  });

  const fetchModelDetails = async () => {
    try {
      const limit = rowsPerPage;
      const response = await getModelDetails(page + 1, limit);
      setModels(response.aiModels);
      setTotalCount(response.totalCount);
    } catch (error) {
      console.error("Error fetching subscribed users:", error);
    }
  };

  const fetchGptPrompts = async () => {
    try {
      const limit = rowsPerPage;
      const search = searchValue;
      const model = filters.model;
      const response = await getGptPrompts(page + 1, limit, search, model);
      setPromptLoader(false);
      setPromptsList(response.prompts);
      setTotalCount(response.totalCount);
    } catch (error) {
      console.error("Error fetching subscribed users:", error);
    }
  };

  useEffect(() => {
    fetchModelDetails();
  }, []);

  useEffect(() => {
    fetchGptPrompts();
  }, [page, rowsPerPage, searchValue, filters]);

  // useEffect(() => {
  //   if (previewPromptData) {
  //     setPreviewResposeOpenAi(previewPromptData.PreviewEditPrompt);
  //   }
  // }, [previewPromptData]);

  const formatPromptsList = promptsList.map((row) => {
    return {
      prompt_key: (
        <>
          <strong>{row.prompt_key}</strong>
        </>
      ),
      prompt_name: (
        <>
          <strong>{row.prompt_name}</strong>
        </>
      ),
      model: (
        <>
          {row.model_identities.map((identity, index) => (
            <React.Fragment key={index}>
              <strong>{identity}</strong>
              {index < row.model_identities.length - 1 && ", "}
            </React.Fragment>
          ))}
        </>
      ),
      variable_info: (
        <>
          <ReactMarkdown children={row.variable_info} />
        </>
      ),
      prompt: (
        <>
          <Box className={classes.hideText}>
            <strong>
              <div>{row.prompt}</div>
            </strong>
          </Box>
        </>
      ),

      action: (
        <>
          <IconButton
            sx={{
              "&:hover": {
                background: theme.colors.primary.lighter,
              },
              color: theme.palette.primary.main,
            }}
            color="inherit"
            size="small"
            onClick={() => handleEditClick(row)}
          >
            <EditTwoToneIcon fontSize="small" sx={{ color: "#0481D9" }} />
          </IconButton>
        </>
      ),
    };
  });

  const handleOpen = () => {
    setOpenPromptDialog(true);
    setIsEditing(false);
    reset({});
  };

  const handleEditClick = async (row) => {
    setIsEditing(true);
    setPromptDetailLoader(true);
    try {
      const response = await getGptPromptsById(row?._id);
      setPromptDetailLoader(false);
      const formData = {
        prompt_name: response.prompt.prompt_name,
        prompt: response.prompt.prompt,
        model_identifer: response?.prompt?.model_identities,
      };
      setValue("model_identifer", response?.model_identities);
      setDynamicVariables(response.prompt.variables.split(/,\s*/));
      reset(formData);
    } catch (error) {
      console.error("Error fetching subscribed users:", error);
    }
    setselectedRowData(row);
    setOpenPromptDialog(true);
  };

  const handleClose = () => {
    setOpenPromptDialog(false);
    setPreviewResposeOpenAi(undefined);
    dynamicFormvalueReset();
  };

  const formSubmit = async (data) => {
    if (isEditing) {
      setUpdatePromptLoader(true);
      if (data.prompt.trim()) {
        const promptData: PromptType = {
          model_identities: data.model_identifer,
          is_active: true,
          prompt_id: selectedRowData?._id,
          variables: selectedRowData.variables,
          variable_info: selectedRowData?.variable_info,
          prompt_name: data.prompt_name,
          prompt: data.prompt,
        };
        try {
          const response = await updatePrompt(promptData);
          setUpdatePromptLoader(false);
          fetchGptPrompts();
        } catch (error) {
          console.error("Error fetching subscribed users:", error);
        }
        handleClose();
      }
    } else {
      if (data.prompt.trim()) {
        const promptData: PromptType = {
          model_identities: data.model_identifer,
          variables: data.variables,
          variable_info: data?.variable_info,
          prompt_name: data.prompt_name,
          prompt: data.prompt,
        };
        try {
          const response = await addPrompt(promptData);
          fetchGptPrompts();
        } catch (error) {
          console.error("Error fetching subscribed users:", error);
        }
        handleClose();
      }
    }
  };

  const handleStatusChange = (e: any): void => {
    let value = null;
    setPromptLoader(true);
    if (e.target.value !== "all") {
      value = e.target.value;
    }
    setFilters((prevFilters) => ({
      ...prevFilters,
      model: value,
    }));
  };

  // Function to convert a string to base64
  const stringToBase64 = (input: string) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(input);
    const base64 = btoa(String.fromCharCode(...data));
    return base64;
  };

  const handleRowsPerPageChange = (rowperpage) => {
    setRowsPerPage(rowperpage);
    setPage(0); // Reset page when changing rows per page
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePreviewClose = () => {
    setOpenPromptPreviewDialog(false);
    setOpenPromptDialog(true);
  };

  const showPreviewDialog = () => {
    setOpenPromptDialog(false);
    setOpenPromptPreviewDialog(true);
  };

  function objectToKeyValuePairs(obj) {
    const keyValuePairs = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        keyValuePairs.push({ key, value: obj[key] });
      }
    }
    return keyValuePairs;
  }

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const handleModelChange = (selectedModels) => {
    setValue("model_identifer", selectedModels);
  };

  const dynamicFormSubmit = async (data) => {
    setPreviewPromptLoader(true);
    const formValues = getValues();
    let payload = {
      variablesValues: objectToKeyValuePairs(data),
      prompt: stringToBase64(formValues.prompt),
    };
    try {
      const resp = await previewPrompt(payload);
      if (resp) {
        setPreviewPromptLoader(false);
        setPreviewResposeOpenAi(resp);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container component="main">
      <Grid container justifyContent="space-between" alignItems="center" sx={{ ms: 2, mt: 2 }}>
        <Grid item>
          <Typography sx={{ mb: 2 }} variant="h3" component="h3" gutterBottom>
            List of Prompts
          </Typography>
        </Grid>
        <Grid item sx={{ display: "flex" }}>
          <Box width={170} sx={{ m: 1 }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select Model</InputLabel>
              <Select onChange={(e) => handleStatusChange(e)} label="Select Model" defaultValue={""} autoWidth>
                {models.map((model) => (
                  <MenuItem key={model._id} value={model.modelIdentity}>
                    {model.modelName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Button sx={{ my: 2 }} variant="contained" onClick={handleOpen} startIcon={<AddTwoToneIcon fontSize="small" />}>
            Add Prompt
          </Button>
        </Grid>
        {/* <Grid item>
          
        </Grid> */}
      </Grid>
      <SharedTable
        columns={columns}
        data={formatPromptsList}
        page={page}
        tableBodyLoader={promptLoader}
        rowsPerPage={rowsPerPage}
        totalRows={totalCount}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        searchFilter={undefined}
        searchFilterVisible={true}
        onSearch={handleSearch}
        selectableRows={false}
        onRowClick={undefined}
      ></SharedTable>

      <Dialog open={openPromptDialog} onClose={handleClose} scroll="paper" aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description" sx={{ overflowX: "hidden" }}>
        <DialogTitle
          id="scroll-dialog-title"
          sx={{
            padding: "16px 24px 0px 24px",
            color: theme.colors.alpha.black[100],
            fontFamily: "TomatoGroteskMedium",
          }}
        >
          {isEditing ? "Update Prompt" : "Add Prompt"}
        </DialogTitle>

        <Box component="form" onSubmit={handleSubmit(formSubmit)} noValidate={true} sx={{ width: 550 }}>
          <DialogContent>
            <Box
              sx={{
                maxHeight: "calc(100vh - 250px)",
                overflowY: "auto",
                maxWidth: "100%",
              }}
            >
              <div>
                <Typography
                  sx={{
                    mb: 1,
                    color: theme.colors.alpha.black[100],
                    fontFamily: "TomatoGroteskMedium",
                  }}
                >
                  Prompt Name
                </Typography>
                <TextField {...register("prompt_name")} label="Prompt Name" margin="normal" fullWidth />
                {errors?.prompt_name && <Typography sx={{ color: theme.colors.error.light }}>{errors?.prompt_name?.message}</Typography>}
              </div>
              <Typography sx={{ mb: 1 }}></Typography>
              <div>
                <Typography
                  sx={{
                    mb: 1,
                    color: theme.colors.alpha.black[100],
                    fontFamily: "TomatoGroteskMedium",
                  }}
                >
                  Prompt
                </Typography>
                <TextArea minRows={10} {...register("prompt")} placeholder="Enter your prompt" />
                {errors?.prompt && <Typography sx={{ color: theme.colors.error.light }}>{errors?.prompt?.message}</Typography>}
              </div>

              <Typography
                sx={{
                  mb: 1,
                  color: theme.colors.alpha.black[100],
                  fontFamily: "TomatoGroteskMedium",
                }}
              >
                Select Model
              </Typography>
              <FormControl fullWidth sx={{ pb: 1 }} error={Boolean(errors.model_identifer)}>
                <InputLabel id="demo-multiple-chip-label">Model</InputLabel>
                <Controller
                  name="model_identifer"
                  control={control}
                  render={({ field }) => (
                    <Select
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      multiple
                      value={field.value || []}
                      input={<OutlinedInput label="Model" />}
                      onChange={(e) => {
                        handleModelChange(e.target.value);
                        field.onChange(e);
                      }}
                      renderValue={(selected) => (
                        <div>
                          {selected.map((value) => (
                            <Chip key={value} label={models.find((model) => model.modelIdentity === value)?.modelName} />
                          ))}
                        </div>
                      )}
                    >
                      {models.map((tokenModel) => (
                        <MenuItem key={tokenModel.modelIdentity} value={tokenModel.modelIdentity}>
                          {tokenModel.modelName}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors?.model_identifer && <Typography sx={{ color: "red" }}>{errors?.model_identifer?.message}</Typography>}
              </FormControl>

              {!isEditing && (
                <>
                  <div>
                    <Typography
                      sx={{
                        mb: 1,
                        color: theme.colors.alpha.black[100],
                        fontFamily: "TomatoGroteskMedium",
                      }}
                    >
                      Variables
                    </Typography>
                    <TextArea {...register("variables")} minRows={3} placeholder="Enter your variables" />
                    {errors?.variables && <Typography color="error">{errors?.variables?.message}</Typography>}
                  </div>

                  <div>
                    <Typography
                      sx={{
                        mb: 1,
                        color: theme.colors.alpha.black[100],
                        fontFamily: "TomatoGroteskMedium",
                      }}
                    >
                      Variable Info
                    </Typography>
                    <TextArea {...register("variable_info")} minRows={4} placeholder="Enter your variable info" />
                    {errors?.variable_info && <Typography color="error">{errors?.variable_info?.message}</Typography>}
                  </div>
                </>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            {isEditing && (
              <Button onClick={showPreviewDialog} variant="contained" color="success">
                Preview
              </Button>
            )}
            <Button
              type="submit"
              variant="contained"
              // disabled={updatePromptLoader}
            >
              {isEditing ? "Update" : "Add"}
            </Button>
          </DialogActions>
        </Box>

        {updatePromptLoader && <SuspenseLoader left="0%" />}
      </Dialog>

      <Dialog
        open={openPromptPreviewDialog}
        onClose={handlePreviewClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ overflowX: "hidden" }}
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={{
            padding: "16px 24px 0px 24px",
            color: theme.colors.alpha.black[100],
            fontFamily: "TomatoGroteskMedium",
          }}
        >
          Preview
        </DialogTitle>
        <Box component="form" onSubmit={handleDynamicForm(dynamicFormSubmit)} noValidate sx={{ width: 550 }}>
          <DialogContent>
            <Box
              sx={{
                maxHeight: "calc(100vh - 250px)",
                overflowY: "auto",
                maxWidth: "100%",
              }}
            >
              <DynamicVariableForm register={registerDynamicForm} dynamicVariables={dynamicVariables} formState={dynamicFormState} />

              {previewResposeOpenAi?.prompt && (
                <code className={classes.codeFormat}>
                  <Typography sx={{ mb: 2 }} variant="h3" component="h3" gutterBottom>
                    Generated prompt preview :
                  </Typography>
                  {previewResposeOpenAi?.prompt}
                </code>
              )}
              {previewResposeOpenAi?.openaiResponse && (
                <code className={classes.codeFormat}>
                  <Typography sx={{ mb: 2 }} variant="h3" component="h3" gutterBottom>
                    Response from OpenAI :
                  </Typography>
                  {previewResposeOpenAi?.openaiResponse}
                </code>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePreviewClose}>Back</Button>
            <Button variant="contained" type="submit" disabled={previewPromptLoader}>
              Get Response
            </Button>
          </DialogActions>
        </Box>
        {previewPromptLoader && <SuspenseLoader left="0%" />}
      </Dialog>
      {promptDetailLoader && <SuspenseLoader left="0%" />}
    </Container>
  );
};

export default PromptList;
