import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  TextField,
  Button,
  Box,
  Container,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  useTheme,
  Grid,
  Typography,
  DialogContentText,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Chip,
} from "@mui/material";
import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { makeStyles } from "@mui/styles";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import SuspenseLoader from "../../shared/components/SuspenseLoader";
import SharedTable from "../../shared/components/Table";
import {
  createIncident,
  createService,
  deletePlan,
  generatePresignedUrl,
  getIncidentList,
  getServiceList,
  getSubscriptionPlans,
  updateIncident,
  updatePlan,
  updateService,
} from "../../logic/apis/api";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  image: {
    maxWidth: 50,
    maxHeight: 50,
  },
  card: {
    marginBottom: 20,
    width: 500,
    height: 130,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  media: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
});

const columns = [
  { id: "title", label: "Title", minWidth: 170 },
  { id: "createdAt", label: "Created At", minWidth: 170 },
  { id: "action", label: "Action", minWidth: 170 },
];

const statuses = [
  { label: "Resolved", value: "Resolved" },
  { label: "Investigating", value: "Investigating" },
  { label: "Completed", value: "Completed" },
  { label: "In Progress", value: "In Progress" },
  { label: "Scheduled", value: "Scheduled" },
];
const Incidents = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isEditing, setIsEditing] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [presignedLoader, setPresignedLoader] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedData, setSelectedData] = useState(null);
  const [openPlanStatus, setOpenPlanStatus] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [addPlanLoader, setAddPlanLoader] = useState(false);
  const [planLoader, setPlanLoader] = useState(true);
  const [updatePlanLoader, setUpdatePlanLoader] = useState(false);
  const [profileImg, setProfileImg] = useState("");
  const [serviceDownInfo, setServiceDownInfo] = useState<boolean>(false);
  const [services, setServices] = useState([]);
  const [updates, setUpdates] = useState([
    { status: "", description: "", fromDate: "", toDate: "" },
  ]);
  const [incidentId, setIncidentId] = useState("");
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      isServiceDown: false,
      service: "",
      updates: [
        {
          status: "",
          description: "",
          fromDate: "",
          toDate: "",
          createdAt: "",
        },
      ],
    },
  });

  const handleAddUpdate = () => {
    setUpdates([
      ...updates,
      { status: "", description: "", fromDate: "", toDate: "" },
    ]);
  };

  const fetchIncidents = async () => {
    try {
      const response = await getIncidentList();
      setPlanLoader(false);
      setProducts(response.incidents);
      //       setTotalCount(response.totalCount);
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };

  const fetchServices = async () => {
    try {
      const response = await getServiceList();
      setPlanLoader(false);
      setServices(response.services);
      //       setTotalCount(response.totalCount);
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };

  useEffect(() => {
    fetchIncidents();
    fetchServices();
  }, []);

  const handleDelete = async () => {
    try {
      const result = await deletePlan(deleteId);
      if (result) {
        setIsDeleting(true);
        setOpenDelete(false);
        fetchIncidents();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setOpenDelete(false);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const onSubmit = async (data) => {
    if (isEditing) {
      setUpdatePlanLoader(true);
      const payloadData = {
        incidentId: incidentId,
        updates: data.updates.map((update) => ({
          status: update?.status,
          description: update.description,
          fromDate: new Date(update.fromDate).getTime() / 1000,
          toDate: new Date(update.toDate).getTime() / 1000,
          createdAt: new Date(),
        })),
        title: data.title,
        isServiceDown: data.isServiceDown === "true",
        serviceId: data?.service,
      };
      try {
        const result = await updateIncident(payloadData);
        if (result) {
          setUpdatePlanLoader(false);
          setOpen(false);
          setIsDeleting(false);
          setOpenPlanStatus(true);
          fetchServices();
          fetchIncidents();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      setAddPlanLoader(true);
      console.log(data?.updates);
      const payloadData = {
        updates: data.updates.map((update) => ({
          status: update?.status,
          description: update.description,
          fromDate: new Date(update.fromDate).getTime() / 1000,
          toDate: new Date(update.toDate).getTime() / 1000,
          createdAt: update.createdAt ? update.createdAt : new Date(),
        })),
        title: data.title,
        isServiceDown: data.isServiceDown === "true",
        serviceId: data?.service,
      };
      try {
        const result = await createIncident(payloadData);
        if (result) {
          setOpen(false);
          setAddPlanLoader(false);
          setIsDeleting(false);
          setOpenPlanStatus(true);
          fetchIncidents();
        }
      } catch (error) {
        setAddPlanLoader(false);
        console.error("Error fetching data:", error);
      }
    }
  };

  function convertUnixTimestampToDateString(timestamp) {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if needed
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  const handleEditClick = (row) => {
    let payload = {
      planId: row._id,
    };
    setIncidentId(row?._id);
    let editData = products.filter((e) => e._id === row._id)[0];

    const initialUpdates = editData?.updates.map((update) => ({
      status: update.status,
      description: update.description,
      fromDate: convertUnixTimestampToDateString(update.fromDate),
      toDate: convertUnixTimestampToDateString(update.toDate),
      createdAt: update.createdAt,
    }));
    console.log(initialUpdates);
    let initial_values = {
      title: editData.title,
      isServiceDown: editData.isServiceDown,
      service: editData.serviceId,
      updates: initialUpdates,
    };
    reset(initial_values);
    setIsEditing(true);
    setSelectedData(payload);
    setOpen(true);
  };

  const handleDeleteClick = (row) => {
    setDeleteId(row._id);
    setOpenDelete(true);
  };

  const handleOpen = () => {
    setOpen(true);
    fetchServices();
    setProfileImg(null);
    setIsEditing(false);
    reset({});
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedData(null);
    setOpenDelete(false);
  };

  const handlePlanStatusClose = () => {
    setOpenPlanStatus(false);
    setUpdatePlanLoader(false);
    setAddPlanLoader(false);
  };

  const formatDate = (dateToFormat) => {
    const date = new Date(dateToFormat);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  const formattedData = products.map((row) => {
    return {
      id: row._id,
      title: row?.title ? row.title : "N/A",
      createdAt: formatDate(row?.createdAt),
      action: (
        <>
          <IconButton
            sx={{
              "&:hover": {
                background: theme.colors.primary.lighter,
              },
              color: theme.palette.primary.main,
            }}
            color="inherit"
            size="small"
            onClick={() => handleEditClick(row)}
          >
            <EditTwoToneIcon fontSize="small" sx={{ color: "#0481D9" }} />
          </IconButton>
        </>
      ),
    };
  });

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRemoveUpdate = (index) => {
    const newUpdates = [...updates];
    newUpdates.splice(index, 1);
    setUpdates(newUpdates);
  };

  const handleRowsPerPageChange = (rowperpage) => {
    const newRowsPerPage = parseInt(rowperpage, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset page when changing rows per page
  };

  return (
    <Container component="main">
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ ms: 2, mt: 2 }}
      >
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            List of Incidents
          </Typography>
        </Grid>
        <Grid item>
          <Button
            sx={{ my: 2 }}
            variant="contained"
            onClick={handleOpen}
            startIcon={<AddTwoToneIcon fontSize="small" />}
          >
            Add Incident
          </Button>
        </Grid>
      </Grid>
      <SharedTable
        columns={columns}
        data={formattedData}
        page={page}
        tableBodyLoader={planLoader}
        rowsPerPage={rowsPerPage}
        totalRows={totalCount}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        searchFilter={undefined}
        searchFilterVisible={false}
        onSearch={undefined}
        selectableRows={false}
        onRowClick={undefined}
      ></SharedTable>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ overflowX: "hidden" }}
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={{ padding: "16px 24px 0px 24px" }}
        >
          {isEditing ? "Update Incident" : "Add Incident"}
        </DialogTitle>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ width: 550 }}
        >
          <DialogContent>
            <Box
              sx={{
                maxHeight: "calc(100vh - 250px)",
                overflowY: "auto",
                maxWidth: "100%",
              }}
            >
              <div>
                <Controller
                  name="title"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Title is required",
                    pattern: {
                      value: /^[^\s][\w\s!@#$%^&*()_+=[\]{}|\\;:'",.<>/?-]*$/,
                      message: "Please enter a valid title",
                    },
                    maxLength: {
                      value: 100,
                      message: "Max length exceeded",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Title"
                      margin="normal"
                      fullWidth
                      error={!!errors.title}
                      helperText={errors?.title?.message}
                    />
                  )}
                />
              </div>
              <Grid item xs={12} md={12}>
                <FormLabel id="isActive">Service down</FormLabel>
                <RadioGroup
                  row
                  aria-label="isActive"
                  name="isActive"
                  value={serviceDownInfo ? "true" : "false"}
                  onChange={(e) =>
                    setServiceDownInfo(e.target.value === "true")
                  }
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio {...register("isServiceDown")} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio {...register("isServiceDown")} />}
                    label="No"
                  />
                </RadioGroup>
              </Grid>
              <Grid>
                <FormControl
                  fullWidth
                  sx={{ pb: 1 }}
                  error={Boolean(errors.service)}
                >
                  <InputLabel id="demo-multiple-chip-label">Service</InputLabel>
                  <Controller
                    name="service"
                    control={control}
                    rules={{ required: "Service is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        input={<OutlinedInput label="Service" />}
                      >
                        {services.map((tokenModel) => (
                          <MenuItem key={tokenModel._id} value={tokenModel._id}>
                            {tokenModel.title}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors?.service && (
                    <Typography sx={{ color: "red" }}>
                      {errors?.service?.message}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
            </Box>
            <Typography sx={{ margin: "10px 0px", fontWeight: 700 }}>
              Updates
            </Typography>
            {updates.map((update, index) => (
              <div key={index}>
                <FormControl fullWidth sx={{ pb: 1 }}>
                  <InputLabel id="demo-multiple-chip-label">Status</InputLabel>
                  <Controller
                    name={`updates[${index}].status` as any}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        input={<OutlinedInput label="Status" />}
                      >
                        {statuses.map((tokenModel) => (
                          <MenuItem
                            key={tokenModel.value}
                            value={tokenModel.value}
                          >
                            {tokenModel.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                {/* <Controller name={`updates[${index}].status`} control={control} defaultValue="" render={({ field }) => <TextField {...field} label="Status" margin="normal" fullWidth />} /> */}
                <Controller
                  name={`updates[${index}].description` as any}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Description"
                      margin="normal"
                      fullWidth
                    />
                  )}
                />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Controller
                      name={`updates[${index}].fromDate` as any}
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="date"
                          label="From Date"
                          InputLabelProps={{ shrink: true }}
                          value={field.value}
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name={`updates[${index}].toDate` as any}
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="date"
                          label="To Date"
                          InputLabelProps={{ shrink: true }}
                          value={field.value}
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                {index !== 0 && (
                  <Button onClick={() => handleRemoveUpdate(index)}>
                    Remove Update
                  </Button>
                )}
              </div>
            ))}
            <Button variant="contained" onClick={handleAddUpdate}>
              Add Update
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              type="submit"
              variant="contained"
              disabled={addPlanLoader || updatePlanLoader}
            >
              Submit
            </Button>
          </DialogActions>
        </Box>
        {(presignedLoader || updatePlanLoader || addPlanLoader) && (
          <SuspenseLoader left="0%" />
        )}
      </Dialog>

      <Dialog
        open={openPlanStatus}
        onClose={handlePlanStatusClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          display="flex"
          justifyContent="flex-end"
          p={1}
          sx={{ overflow: "hidden" }}
        >
          <IconButton
            edge="end"
            color="primary"
            onClick={handlePlanStatusClose}
            aria-label="close"
          >
            <CancelIcon sx={{ fontSize: 30, color: "#0481D9" }} />
          </IconButton>
        </Box>

        <DialogContent sx={{ width: 324, height: 240 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CheckCircleIcon color="success" sx={{ fontSize: 70, m: 2 }} />
            <DialogContentText
              id="alert-dialog-description"
              sx={{ color: "black" }}
            >
              <strong>
                Incident{" "}
                {isDeleting ? "Deleted" : isEditing ? "Updated" : "Created"}{" "}
                Successfully
              </strong>
            </DialogContentText>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openDelete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: 400, height: 300 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <DeleteIcon
              sx={{ fontSize: 70, m: 2, color: "rgba(204, 43, 53, 1)" }}
            />
            <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>
              Delete
            </Typography>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ m: 2, fontWeight: 600 }}
            >
              Are you sure you want to delete this Incident?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ background: "rgba(204, 43, 53, 1)" }}
            onClick={handleDelete}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Incidents;
