import { Card } from "@mui/material";
import { useEffect, useState } from "react";
import { allAffiliates } from "../../logic/apis/api";
import SharedTable from "../../shared/components/Table";

const columns = [
  { id: "name", label: "Name", width: "300px" },
  { id: "email", label: "Email", width: "250px" },
  { id: "joinedOn", label: "Joined On", width: "250px" },
  { id: "code", label: "Affiliate Code", width: "250px" },
];

const ActiveUsers = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [affiliates, setAffiliates] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [affiliateLoader, setAffiliateLoader] = useState(true);

  const formatDate = (dateToFormat) => {
    if (dateToFormat) {
      const date = new Date(dateToFormat);
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    } else {
      return "";
    }
  };

  const fetchAffiliates = async () => {
    try {
      const limit = rowsPerPage;
      const response = await allAffiliates(page, limit, "ACCEPTED");
      setAffiliates(response.data?.affiliates);
      setTotalCount(response.data?.affilaiteCount);
      setAffiliateLoader(false);
    } catch (error) {
      console.error("Error fetching subscribed users:", error);
    }
  };

  const handleRowsPerPageChange = (rowperpage) => {
    setRowsPerPage(rowperpage);
    setPage(0);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    fetchAffiliates();
  }, [rowsPerPage, page]);

  const formatActiveAffiliates = (affiliates || []).map((row) => {
    let imageUrl = row?.user?.profilePicture || "";
    if (imageUrl.includes("?")) {
      imageUrl = imageUrl.split("?")[0];
    }
    return {
      id: row._id,
      name: (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            {imageUrl ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundImage: "linear-gradient(to right, rgba(85, 105, 255, 1), rgba(30, 136, 229, 1), rgba(52, 163, 83, 1))",
                  borderRadius: "50%",
                  padding: "2px",
                  width: "50px",
                  height: "50px",
                }}
              >
                <img src={imageUrl} style={{ width: "45px", height: "45px", borderRadius: "50%" }} alt="description" />
              </div>
            ) : null}
            <div style={{ display: "flex", flexDirection: "column", alignItems: "left", paddingLeft: "10px" }}>{row?.user?.name ? <strong>{row?.user?.name}</strong> : <strong>N/A</strong>}</div>
          </div>
        </>
      ),
      email: <>{row?.user?.email}</>,
      joinedOn: formatDate(row?.createdAt),
      code: <>{row?.affiliateReferralCode}</>,
    };
  });

  return (
    <>
      <SharedTable
        columns={columns}
        data={formatActiveAffiliates}
        page={page}
        tableBodyLoader={affiliateLoader}
        rowsPerPage={rowsPerPage}
        totalRows={totalCount}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        searchFilter={undefined}
        searchFilterVisible={false}
        onSearch={undefined}
        selectableRows={false}
        onRowClick={undefined}
      ></SharedTable>
    </>
  );
};
export default ActiveUsers;
