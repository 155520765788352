import { Card, CardContent, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { SyntheticEvent, useEffect, useState } from "react";
import { allAffiliates } from "../../logic/apis/api";
import UserRequest from "./userRequests";
import ActiveUsers from "./activeUsers";
import SettlementUserRequest from "./settlementUsers";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AffiliateProgram = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ width: "100%" }}>
          <Tabs variant="scrollable" scrollButtons="auto" textColor="primary" indicatorColor="primary" value={value} onChange={handleChange} aria-label="earn-program">
            <Tab label="User Request" {...a11yProps(0)} />
            <Tab label="Active Users" {...a11yProps(1)} />
            <Tab label="Settlement Request" {...a11yProps(2)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <UserRequest />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ActiveUsers />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <SettlementUserRequest />
          </TabPanel>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AffiliateProgram;
