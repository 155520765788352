import styled from "styled-components";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #a9aac21e;
  z-index: 9999;
  backdrop-filter: blur(5px);
`;

export const ModalContent = styled.div<{ nopadding: boolean | undefined }>`
  width: 800px;
  background-color: #ffffff;
  padding: ${({ nopadding }) => (nopadding ? "0px" : "36px")};
  border-radius: 20px;
  box-shadow: 0px 98px 66px 0px rgba(19, 18, 66, 0.02),
    1px 4px 104px 0px rgba(20, 20, 43, 0.04),
    0px 54px 54px 0px rgba(74, 58, 255, 0.02);
`;
