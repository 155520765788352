
let navigateCallback:any;

export const setNavigateCallback = (callback) => {
  navigateCallback = callback;
};

export const navigateTo = (path) => {
  if (navigateCallback) {
    navigateCallback(path);
  } else {
    console.error("Navigate callback is not set. Navigation will not work.");
    return;
  }
};